<template>
  <div class="ctk-search-form tw-overflow-x-hidden">
    <search-form-countries
      :edition="edition"
      @open-modal="toggleCountriesModal"
    />

    <predefined-dimensions
      @update="updateDimension"
    />

    <dimensions
      ref="dimensions"
      @input="updateDimension"
    />
    <b-form-checkbox
      v-model="withoutTailLift"
      @input="getOffers({ fromFilter: true })"
    >
      {{ $t('offer_list.search_engine.without_tail_lift') }}
    </b-form-checkbox>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { debounce } from 'underscore'
  import { mapActions, mapGetters } from 'vuex'

  import PredefinedDimensions from './_subs/PredefinedDimensions/index.vue'
  import Dimensions from './_subs/Dimensions/index.vue'
  import SearchFormCountries from './_subs/SearchFormCountries/index.vue'

  function getOptions () {
    return {
      bgStyle: { backgroundColor: '#E9E9EA' },
      piecewiseStyle: { boxShadow: 'none', width: '40px', height: '20px' },
      sliderStyle: [
        { backgroundColor: '#277696', boxShadow: 'none' },
        { backgroundColor: '#277696', boxShadow: 'none', border: '1px solid white' }
      ],
      processStyle: { backgroundColor: '#277696' },
      tooltip: false
    }
  }

  export default defineComponent({
    name: 'SearchForm',
    components: {
      PredefinedDimensions,
      Dimensions,
      SearchFormCountries
    },
    props: {
      edition: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        dimensions: {
          length: { min: 0, max: 1320, interval: 10, unit: 'cm', value: null },
          width: { min: 0, max: 240, interval: 10, unit: 'cm', value: null },
          height: { min: 0, max: 270, interval: 10, unit: 'cm', value: null },
          weight: { min: 0, max: 28000, interval: 50, unit: 'kg', value: null }
        },
        options: getOptions()
      }
    },
    computed: {
      ...mapGetters('offers', [
        'getCurrentSearch'
      ]),
      /**
       * Creates a single computed property to watch all the dimensions at the same
       * time, instead of watching them individually.
       * @function searchDimensions
       * @returns {string}
       */
      searchDimensions () {
        const {
          min_length: minLength,
          max_length: maxLength,
          max_width: width,
          max_height: height,
          max_weight: weight
        } = this.getCurrentSearch

        return [minLength, maxLength, width, height, weight].join('-')
      },
      withoutTailLift: {
        get () {
          return this.getCurrentSearch.without_tail_lift
        },
        set (v) {
          if (this.$matomo) {
            this.$matomo.trackEvent('Freight Searches', 'Changed Load', 'TailLift', v ? 1 : 0)
          }

          this.setCurrentSearch({
            search: {
              ...this.getCurrentSearch,
              without_tail_lift: v
            }
          })
        }
      }
    },
    methods: {
      ...mapActions('offers', [
        'setOffersSuggestionsVisibility',
        'setCurrentSearch',
        'retrieveOffers'
      ]),
      toggleCountriesModal (direction) {
        if (this.$matomo) {
          const eventNames = {
            pickup: 'Initiated Pickup',
            delivery: 'Initiated Delivery',
            both: 'Initiated Pickup And Delivery'
          }

          this.$matomo.trackEvent('Freight Searches', eventNames[direction])
        }
        this.$emit('toggle-countries-modal', direction)
      },
      updateDimension (dimensions) {
        const { length, weight, width, height } = dimensions
        this.setCurrentSearch({
          search: {
            ...this.getCurrentSearch,
            min_length: length.min,
            max_length: length.max,
            max_weight: weight.max,
            max_width: width.max,
            max_height: height.max
          }
        })
        this.getOffers({ pageNumber: null, fromFilter: true })
      },
      getOffers: debounce(
        function ({ fromFilter }) {
          if (!this.$parent.$parent.$parent.saveModal) {
            this.retrieveOffers(fromFilter)
              .catch(() => {})
          }
        },
        600
      )
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-search-form {
    .divider {
      width: 100%;
      border-top: 2px solid #F6F6F6;
    }
  }

</style>
