<template>
  <b-row class="tw-flex tw-w-full">
    <b-col class="tw-flex tw-text-center tw-max-h-full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        class="tw-flex tw-w-full"
        viewBox="0 0 755 620"
      >
        <g>
          <path
            v-for="(city, k) in cities"
            :id="city.code"
            :key="k"
            v-b-tooltip.hover
            :class="{
              'active': selectedKey.includes(city.code),
              'hover': overId === city.code
            }"
            :d="city.path"
            :title="city.name"
            class="land"
            @mousemove="onEnter(city)"
            @mouseleave="onLeave(city)"
            @click="onClick(city)"
          />
        </g>
      </svg>
    </b-col>
  </b-row>
</template>

<script>
  import geonames from '@/assets/geo/geonames.json'
  import CountryComponent from '@/mixins/country-component'

  export default {
    name: 'BeSVG',
    mixins: [CountryComponent],
    data () {
      return {
        geonames,
        cities: [
          {
            name: geonames['BE-VAN'],
            code: 'BE-VAN',
            path: 'M435.75,1.31l11.07,5.98l1.3,18.09l-14.38,2.36l22.27,-0.87l6.24,7.37l21.16,-28.48l9.67,4.64l5.1,12.2l-6.34,11.98l12.24,24.36l20.56,3.44l-0.62,13.46l0,0l-4.49,12.64l9.3,21.54l-48.55,23.15l-6.09,12.47l0,0l-31.92,7.25l-5.06,-8.57l-18.38,12.34l-2.57,-8.05l-19.61,10.45l-36.53,-1.36l-2.88,-10.76l-27.31,-2.13l0,0l-9.63,-1.02l-3.05,-18.77l27.04,-7.7l3.35,-9.06l-8.19,-30.53l6.24,-9.27l-16.4,-21.42l0,0l-4.08,-6.56l41.88,3.11L356.72,18l18.44,-9.75l12.66,-1.28l-0.49,18.24l20.25,0.12L435.75,1.31z'
          },
          {
            name: geonames['BE-WBR'],
            code: 'BE-WBR',
            path: 'M302.66,246.58L313.34,239.28L331.33,251.94L348.45,238L355.88,243.42L379.34,231.41L385.53,239.93L399.24,228.83L406.81,235.36L408.44,218.03L431.34,215.73L463.43,235.21L474.48,226.96L481.78,233.03L481.78,233.03L474.66,266.23L474.66,266.23L437.15,282.31L412.45,277.36L413.42,289.64L400.16,288.76L395.72,297.19L395.72,297.19L374.09,301.21L374.69,291.9L363.45,289.92L347.91,296.39L318.55,260.26L303.87,269.26z'
          },
          {
            name: geonames['BE-BRU'],
            code: 'BE-BRU',
            path: 'M366.21,187.61L366.99,188.19L366.99,188.19L378.5,220.21L358,228.61L330.96,212.1L341.25,192.28L356.93,187.64L356.93,187.64L356.93,187.64L356.93,187.64L365.59,186.22L365.59,186.22z'
          },
          {
            name: geonames['BE-WHT'],
            code: 'BE-WHT',
            path: 'M178.07,227.85l33.19,14.35l9.22,-15.76l15.85,-2.7l3.21,10.27l23.56,4.72l0,0l3.11,12.74l36.45,-4.9l0,0l1.21,22.68l14.69,-8.99l29.35,36.13l15.54,-6.47l11.24,1.99l-0.6,9.31l21.63,-4.03l0,0l-1.36,30.58l8.91,5.12l-4.55,29.48l-37.02,4.68l-22.45,16.94l21.96,7.04l-7.37,19.33l13.67,68.37l0,0l-56.49,-11.19l3.52,-20.77l13.64,-6.38l-5.88,-19.26l-13.97,-0.21l15.56,-42.01l-8.99,-5.19l-4.8,9.47l-22,-30.68l-23.85,9.73l-30.07,-7.36l-7.7,14.01l-9.69,-19.6l0.31,-26.39l-10.13,-12.01l-21.01,2.68l3.54,-11.07l-8.58,-2.93l-19.2,12.81l-16.44,-10.13l-10.15,-40.79l4.16,-13.35l-16.17,-16.73l0,0l25,0.18l10.5,14.04L178.07,227.85zM61.92,246.39l-4.07,-13.33l23.17,-7.24l-4.61,-6.08l12.39,-5.19l3.62,11.51l0,0l-15.46,8.86l-5.82,15.75L61.92,246.39z'
          },
          {
            name: geonames['BE-WLG'],
            code: 'BE-WLG',
            path: 'M612.63,214.16L610.61,230.61L610.61,230.61L649.55,245.57L651.44,231.37L651.44,231.37L676.4,231.47L680.21,242.69L695.23,241.61L706.98,271L724.8,270.97L707.19,296.7L716.45,310.98L740.65,312.99L745.63,329.08L738.78,346.22L751.62,359.84L742.63,369.32L732.13,364.79L726.84,380.46L706.71,390.08L709.53,405.99L700.83,408.2L699.57,422.04L694.51,411.02L684.3,414.98L677.22,405.84L677.22,405.84L668.4,361.27L645.7,356.07L642.32,376.24L649.3,376.7L635,384.31L618.72,382.16L618.31,353.03L582.29,342.53L577.5,332.64L568.96,338L559.72,331.24L554.49,346.67L554.49,346.67L535.82,350.99L523.66,339.28L516.4,316.16L509.59,319.33L505.33,305.91L484.73,299.66L474.66,266.23L474.66,266.23L481.78,233.03L481.78,233.03L497.46,245.66L497.46,245.66L510.08,249.73L511.87,241L537.14,243.36L553.19,233.74L559.18,242.62L570.62,241.15L611.54,214.13L611.54,214.13z'
          },
          {
            name: geonames['BE-VLI'],
            code: 'BE-VLI',
            path: 'M630.3,227.39l21.14,3.99l0,0l-1.89,14.19l-38.94,-14.96l0,0L630.3,227.39zM581.9,70.45l5.1,17.2l56.92,24.11l-26.01,56.17l7.12,3.79l-21.88,24.15l8.4,18.25l0,0l-40.93,27.02l-11.44,1.47l-5.99,-8.89l-16.05,9.62L511.87,241l-1.79,8.73l-12.63,-4.07l0,0l16.55,-59.62l-18.38,-1.66l-9.43,-10.31l14.34,-24.04l8.05,-1.11l-7.84,-7.9l-11.65,10.83l-14.9,-6.2l0,0l6.09,-12.47l48.55,-23.15l-9.3,-21.54l4.49,-12.64l0,0l34.94,-0.56l13.34,-11.82L581.9,70.45z'
          },
          {
            name: geonames['BE-WLX'],
            code: 'BE-WLX',
            path: 'M554.49,346.67L559.72,331.24L568.96,338L577.5,332.64L582.29,342.53L618.31,353.03L618.72,382.16L635,384.31L649.3,376.7L642.32,376.24L645.7,356.07L668.4,361.27L677.22,405.84L677.22,405.84L652.43,427.39L637.26,457.43L640.65,467.02L628.76,473.09L620.9,491.68L630.38,497.82L621.89,510.7L624.58,524.29L631.17,523L638.51,544.86L649.96,548.91L645.96,558.65L654.79,562.46L641.29,595.59L604.11,596.01L596.64,609.19L585.98,602.41L569.66,612L561.26,582.67L545.6,572.22L538.79,578.12L542.2,565.3L531.4,553.76L510.04,553.52L496,532.67L471.58,521.01L471.58,521.01L475.61,505.53L501.07,484.63L472.54,452.72L485.96,445.32L490.61,424.37L492.61,433.38L530.23,427.24L525.02,421.1L532.53,413.32L518.21,396.14L554.55,374.3L553.48,365.88L544.53,366.28L554.59,359.81z'
          },
          {
            name: geonames['BE-WNA'],
            code: 'BE-WNA',
            path: 'M395.72,297.19L400.16,288.76L413.42,289.64L412.45,277.36L437.15,282.31L474.66,266.23L474.66,266.23L484.73,299.66L505.33,305.91L509.59,319.33L516.4,316.16L523.66,339.28L535.82,350.99L554.49,346.67L554.49,346.67L554.59,359.81L544.53,366.28L553.48,365.88L554.55,374.3L518.21,396.14L532.53,413.32L525.02,421.1L530.23,427.24L492.61,433.38L490.61,424.37L485.96,445.32L472.54,452.72L501.07,484.63L475.61,505.53L471.58,521.01L471.58,521.01L448.75,523.54L447.89,501.4L455.89,488.52L436.69,470.67L446.72,449.76L442.62,441.63L457.44,419.95L443.92,410.35L418.03,435.56L417.86,462.5L367.51,478.72L367.51,478.72L353.84,410.35L361.21,391.02L339.25,383.98L361.7,367.04L398.72,362.36L403.27,332.88L394.36,327.77z'
          },
          {
            name: geonames['BE-VOV'],
            code: 'BE-VOV',
            path: 'M329.29,47.05L345.69,68.47L339.44,77.74L347.63,108.27L344.29,117.33L317.25,125.03L320.3,143.8L329.93,144.82L329.93,144.82L328.51,155.8L311.39,166.06L314.97,177.15L301.77,177.02L291.34,205.99L295.42,221.01L273.44,225.27L273.99,238.73L263.09,238.73L263.09,238.73L239.54,234.01L236.33,223.74L220.47,226.44L211.25,242.2L178.07,227.85L178.07,227.85L190.13,216.09L176.73,197.02L181.78,187.82L169.64,185.61L178.32,173.76L169.26,169.48L175.56,141.61L153.21,125.68L168.63,106.66L158.77,96.98L162.91,71.35L162.91,71.35L172.06,80.62L191.26,80.25L189,67.48L203.73,61.61L240,74.86L242.65,89.89L261.36,87.22L261.27,94.25L295.13,80.72z'
          },
          {
            name: geonames['BE-VBR'],
            code: 'BE-VBR',
            path: 'M474.19,145.65l14.9,6.2l11.65,-10.83l7.84,7.9l-8.05,1.11l-14.34,24.04l9.43,10.31l18.38,1.66l-16.55,59.62l0,0l-15.68,-12.63l0,0l-7.29,-6.07l-11.05,8.25l-32.1,-19.48l-22.9,2.3l-1.63,17.33l-7.57,-6.53l-13.71,11.1l-6.19,-8.52l-23.46,12.02l-7.43,-5.42l-17.12,13.94l-17.99,-12.65l-10.68,7.29l0,0l-36.45,4.9l-3.11,-12.74l0,0h10.89l-0.54,-13.46l21.98,-4.26l-4.08,-15.01l10.43,-28.97l13.21,0.12l-3.58,-11.09l17.12,-10.26l1.42,-10.98l0,0l27.31,2.13l2.88,10.76l36.53,1.36l19.61,-10.45l2.57,8.05l18.38,-12.34l5.06,8.57L474.19,145.65zM365.59,186.22L365.59,186.22l-20.99,2.95l-13.52,23.29L358,228.61l20.5,-8.41l-11.52,-32.01L365.59,186.22z'
          },
          {
            name: geonames['BE-VWV'],
            code: 'BE-VWV',
            path: 'M160.4,42.39L162.91,71.35L162.91,71.35L158.77,96.98L168.63,106.66L153.21,125.68L175.56,141.61L169.26,169.48L178.32,173.76L169.64,185.61L181.78,187.82L176.73,197.02L190.13,216.09L178.07,227.85L178.07,227.85L158.63,245.6L148.13,231.56L123.13,231.37L123.13,231.37L117.84,220.98L92.42,226.07L92.42,226.07L88.8,214.56L76.41,219.75L81.02,225.82L57.85,233.06L61.92,246.39L61.92,246.39L47.74,240.45L34.84,214.74L17.8,214.1L10.85,202.67L9.05,181.12L17.14,172.59L0,128.86L124.26,53.82L124.77,43.66L127.12,54.29L127.18,43.41L132.98,50.62z'
          }
        ]
      }
    }
  }
</script>
