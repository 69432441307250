<template>
  <button
    :title="$t(country)"
    class="country-list-item tw-appearance-none tw-bg-white tw-border-none tw-py-0 tw-px-4 md:tw-px-5 tw-cursor-pointer tw-relative"
    type="button"
  >
    <div class="tw-flex tw-justify-between">
      <div class="tw-flex tw-items-center tw-mr-1 md:tw-mr-4">
        <ui-flag
          :country="country"
          class="tw-mr-1 md:tw-mr-3"
        />
        <div class="tw-hidden md:tw-block country-list-item__name">
          {{ $t(country) }}
          <span
            v-if="$attrs.disabled"
            v-text="'*'"
          />
        </div>
      </div>

      <template
        v-if="sections.length > 0"
      >
        <UiBadge
          :class="{
            active: isSelected
          }"
          class="country-list-item__sections"
        >
          <i18n
            path="offer_list.search_engine.geo_selection.x_out_of_y"
            tag="span"
          >
            <template #x>
              <span
                class="tw-font-bold"
              >
                {{ $n(selectedSections.length) }}
              </span>
            </template>
            <template #y>
              <span>
                {{ $n(sections.length) }}
              </span>
            </template>
          </i18n>
        </UiBadge>
      </template>
      <template
        v-else
      >
        <b-form-checkbox
          :checked="isSelected"
          :id="'checkbox' + country"
          class="tw-mt-1 tw-pointer-events-none"
        />
      </template>

      <span
        v-if="!!$attrs.disabled"
        v-text="'*'"
        class="country-list-item__floating-disabled md:tw-hidden"
      />
    </div>
  </button>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkChip from '@/components/CtkChip/index.vue'

  /**
   * Single button item on the country list.
   * @module component - CountryListItem
   * @param {string} country - Country iso-2 code
   * @param {Array} sections - The list of sections for the country. If the country
   * does not have subdivisions (Luxembourg, Andorra, ..), the list is empty.
   */
  export default defineComponent({
    name: 'CountryListItem',
    components: {
      CtkChip
    },
    props: {
      country: {
        type: String,
        required: true
      },
      sections: {
        type: Array,
        default: null
      },
      selectedSections: {
        type: [Array, Boolean],
        default: () => ([])
      }
    },
    computed: {
      /**
       * Returns true if one of the sections has been selected
       * @function isSelected
       * @returns {boolean}
       */
      isSelected () {
        return typeof this.selectedSections === 'boolean'
          ? this.selectedSections
          : this.selectedSections.length > 0
      }
    }
  })
</script>

<style lang="scss" scoped>

  .country-list-item {
    color: $primary-text;
    height: 50px;
    outline: none;
    border-right: 1px solid $divider;
    transition: background-color 100ms;

    &:hover,
    &.active {
      background-color: $light-gray;
    }

    &:focus {
      background-color: $light-gray;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:focus:hover,
    &:focus.active {
      background-color: darken($light-gray, 5%);
    }

    &__sections {
      background-color: $gray !important;
      color: $primary-text !important;

      &.active {
        background-color: $primary !important;
        color: white !important;
      }
    }

    &__floating-disabled {
      position: absolute;
      top: 0.1rem;
      right: 0.25rem;
    }

    /* stylelint-disable */
    &:disabled,
    &:disabled .country-list-item__sections {
      cursor: not-allowed;
      opacity: 0.5;
    }
    /* stylelint-enable */

    @media only screen and (min-width: $breakpoint-tablet) {
      border-bottom: 1px solid $divider;
      border-right: none;
    }
  }

</style>
