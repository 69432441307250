<template>
  <div class="tw-flex tw-rounded tw-py-0 tw-px-1 dimension-input">
    <input
      :id="type"
      v-model.lazy="tempValue"
      :name="type"
      :min="min"
      :max="max"
      :step="interval"
      class="tw-mr-1 tw-border-none tw-text-right tw-flex-1"
      type="number"
      @blur="onBlur"
    >
    <span class="unit">
      {{ unit }}
    </span>
  </div>
</template>

<script>
  /**
   * @module component - dimensionInput
   * @param {string} type
   * @param {number} min
   * @param {number} max
   * @param {number} interval
   * @param {string} unit
   * @emits input
   */
  export default {
    name: 'DimensionInput',
    props: {
      type: {
        type: String,
        required: true,
        default: null
      },
      min: {
        type: Number,
        default: null
      },
      max: {
        type: Number,
        default: null
      },
      interval: {
        type: Number,
        default: null
      },
      unit: {
        type: String,
        default: null
      },
      value: {
        type: [String, Number],
        default: null
      }
    },
    methods: {
      onBlur () {
        this.$emit('blur')
      }
    },
    computed: {
      tempValue: {
        get () {
          return this.value
        },
        set (v) {
          const parsedValue = parseInt(v, 10)
          this.$emit('input', parsedValue)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .dimension-input {
    background-color: $light-gray;

    &,
    & input {
      height: 18px;
    }

    & input,
    & .unit {
      line-height: 18px;
    }

    input {
      background-color: transparent;
      max-width: 80px;
      min-width: 30px;
    }

    input[type='number'] {
      -moz-appearance: textfield; /* stylelint-disable-line */
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none; /* stylelint-disable-line */
    }

    .unit {
      line-height: 18px;
    }
  }

</style>
