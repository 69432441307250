<template>
  <ctk-side-bar
    ref="searchSideBar"
    class="search-side-bar"
    :open="isOpen"
    :width="350"
    :without-close-btn="searchActive"
    @toggle-menu="toggleMenu"
  >
    <ctk-collapse-category-item
      :title="$t('offers.titles.freight_search')"
      :icon="'search'"
      data-test="freight-search-category"
    />

    <ctk-collapse-nav-item
      :to="{
        name: 'Offers'
      }"
      :title="$t('offers.titles.all_offers')"
      :count="getOffersMetrics.total"
      :has-dot="getOffersMetrics.unviewed_item_count > 0"
      :dot-tooltip="getOffersMetrics.unviewed_item_count > 0
        ? $tc('offers.values.unviewed_offers', getOffersMetrics.unviewed_item_count, {
          count: $n(getOffersMetrics.unviewed_item_count)
        })
        : null"
      :class="{
        'router-link-active': ['Offers', 'Searches'].includes($route.name) && !$route.params.uuid
      }"
      active-class=""
      class="freight-search-item tw-mb-8"
      @click.native="selectSection"
    />

    <modify-search-view
      v-show="searchActive"
      :show="searchActive"
      :edit="isSearchEdit"
      @close="searchActive = false"
      @save-search="saveSearch"
      @toggle-countries-modal="toggleCountriesDivisionsModal"
    />

    <div
      class="tw-mb-6"
    >
      <ctk-collapse-category-item
        :title="$t('proposal_list.title')"
        :icon="'bid-2'"
        data-test="proposal-category"
      />

      <ctk-collapse-nav-item
        :to="{
          name: 'ProposalStates',
          params: {
            state: 'pending'
          }
        }"
        :title="$t('offer.proposals.filters.pending')"
        :count="getProposalsMetrics.pending"
        :exact="true"
        class="proposal-section"
        @click.native="selectSection"
      />

      <ctk-collapse-nav-item
        :to="{
          name: 'Proposals'
        }"
        :title="$t('offer.proposals.filters.all')"
        :count="getProposalsMetrics.total"
        :exact="true"
        class="proposal-section"
        @click.native="selectSection"
      />
    </div>

    <searches-saved-section
      :open="isSavedSearchesSectionOpen"
      class="tw-mb-8"
      @modify-current-search="searchEdit"
      @open-new-search-section="$router.push({ name: 'Offers' }).catch(() => {})"
      @selected="selectSection"
      @toggle="goToSearches($event)"
    />
  </ctk-side-bar>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapActions, mapGetters } from 'vuex'

  import CtkSideBar from '@/components/CtkSideBar/index.vue'
  import SearchesSavedSection from './_subs/SearchesSavedSection/index.vue'
  import ModifySearchView from './_subs/ModifySearchView/index.vue'
  import CtkCollapseNavItem from '@/components/CtkCollapseSimple/_subs/CtkCollapseNavItem/index.vue'
  import CtkCollapseCategoryItem from '@/components/CtkCollapseSimple/_subs/CtkCollapseCategoryItem/index.vue'

  import { EventBus } from '@/services/EventBus'

  export default defineComponent({
    name: 'SearchSideBar',
    components: {
      CtkSideBar,
      CtkCollapseNavItem,
      SearchesSavedSection,
      ModifySearchView,
      CtkCollapseCategoryItem
    },
    props: {
      open: {
        type: Boolean,
        default: true
      },
      width: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        searchActive: false,
        isSearchEdit: true
      }
    },
    computed: {
      ...mapGetters('ui', [
        'isSidebarOpen'
      ]),
      ...mapGetters('offers', [
        'getCurrentSearch',
        'getSavedSearches',
        'getProposalsMeta',
        'getProposalsMetrics',
        'getOffersMetrics'
      ]),
      searches () {
        return this.$store.state.offers.searches
      },
      isOpen: {
        get () {
          return this.isSidebarOpen
        },
        set (v) {
          this.setSidebarOpen(v)
        }
      },
      /**
       * Returns true if the new search section should be open
       * @function isSavedSearchesSectionOpen
       * @returns {boolean}
       */
      isSavedSearchesSectionOpen () {
        return (this.$route.name === 'Searches') && this.isOpen
      },
      /**
       * Returns true if the new search section should be open
       * @function isNewSearchSectionOpen
       * @returns {boolean}
       */
      isNewSearchSectionOpen () {
        return (this.$route.name === 'Offers') && this.isOpen
      }
    },
    mounted () {
      // Responsive toggle
      this.toggleOnResize()
      window.addEventListener('resize', this.toggleOnResize)

      EventBus.$on('offers:filter', () => {
        this.setSidebarOpen(true)
        this.searchActive = true
        this.isSearchEdit = false
      })

      EventBus.$on('offers:set-search-active', (/** @type {boolean} */ v) => {
        this.searchActive = v
      })
    },
    created () {
      this.retrieveProposalsMetrics()
      EventBus.$on('refresh-proposal-section', () => { this.retrieveProposalsMetrics() })
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.toggleOnResize)

      const events = ['offers:filter', 'refresh-proposal-section', 'offers:set-search-active']
      events.forEach(event => {
        EventBus.$off(event)
      })
    },
    methods: {
      ...mapActions('ui', [
        'setSidebarOpen'
      ]),
      ...mapActions('offers', [
        'resetCurrentOffer',
        'setKeyboardStatus',
        'setOffersSuggestionsVisibility',
        'getOffers',
        'retrieveProposalsMetrics'
      ]),
      /**
       * @function searchEdit
       */
      searchEdit () {
        this.searchActive = true
        this.isSearchEdit = true
      },
      goToAllProposals ($event) {
        this.resetCurrentOffer()
        this.changeRoute($event, { name: 'Proposals', params: { state: 'all' } })
      },
      goToSearches (event) {
        if (!this.isOpen && event) {
          this.isOpen = true
        }

        /**
         * If we're not already in the search view, redirect the
         * user to one of them according to his
         * display mode.
         */
        if (!['Searches'].includes(this.$route.name)) {
          const currentSearchUuid = this.$route.params.uuid || this.getCurrentSearch.uuid

          this.changeRoute(event, this.getSavedSearches.length
            ? { name: 'Searches', params: { uuid: currentSearchUuid || this.getSavedSearches[0].uuid } }
            : { name: 'Searches' }
          )
        }
      },
      changeRoute (bool, route) {
        if (bool) {
          this.$router.push(route)
            .catch(() => {})
        }
      },
      toggleOnResize () {
        const width = document.documentElement.clientWidth
        if (width <= 1024) {
          this.isOpen = false
        }
      },
      saveSearch () {
        this.$emit('save-search')
        this.setKeyboardStatus(false)
      },
      resetSearch (val) {
        if (!this.isOpen && val) {
          this.isOpen = true
        }

        if (!val) return

        setTimeout(() => {
          EventBus.$emit('refresh-slider')
        }, 200)

        if (this.$route.name !== 'Offers') {
          this.$router.push({
            name: 'Offers'
          })
            .catch(() => {})
        }
      },
      /**
       * @function selectSection
       */
      selectSection (mobile) {
        if (this.isOpen) {
          this.toggleMenu(mobile)
        } else {
          this.isOpen = true
        }

        this.resetCurrentOffer()
        this.setOffersSuggestionsVisibility(false)
      },
      /**
       * Toggle the search side bar
       * @function toggleMenu
       * @param {boolean} mobile - Specify if the user called the function, being in mobile.
       * This allows the user to close the side menu when he clicks on a button, inside the
       * side menu.
       */
      toggleMenu (mobile = false) {
        if (mobile) {
          const width = document.documentElement.clientWidth
          if (width <= 768) {
            this.isOpen = !this.isOpen
          }
        } else {
          this.isOpen = !this.isOpen
        }

        setTimeout(() => {
          EventBus.$emit('request-map-refresh')
          EventBus.$emit('refresh-slider')
        }, 250)
      },
      toggleCountriesDivisionsModal (direction) {
        this.$emit('open-countries-dialog', direction)
      }
    }
  })
</script>

<style lang="scss">

  .offers {
    .hide-side-bar {
      .ctk-collapse-nav-item__badge__icon {
        background-color: $light-blue;
      }
    }
  }

  .search-side-bar .freight-search-item .ctk-collapse-nav-item__badge__icon {
    background-color: $teal;
  }

  .search-side-bar .proposal-section .ctk-collapse-nav-item__badge__icon {
    background-color: $light-blue;
  }

  .search-side-bar .content {
    flex-direction: column;
    padding: 30px 28px;
    overflow: auto;
  }

</style>

<style lang="scss" scoped>

  .search-side-bar {
    .ctk-collapse {
      margin-bottom: 10px;
    }

    .proposal-section,
    .freight-search-item {
      padding-left: 30px;
    }
  }

</style>
