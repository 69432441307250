<template>
  <ctk-sort-dropdown
    id="sort-offers"
    class="offers-sort-dropdown"
    :initial-value="'pickup_date'"
    :value="[
      {
        value: getOffersSortType
      }
    ]"
    :text="valueText"
    :items="items"
    :small="false"
    @input="sortOffers"
  />
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import CtkSortDropdown from '@/components/CtkSortDropdown/index.vue'
  import { EventBus } from '@/services/EventBus'
  import Storage from '@/services/Storage'

  /**
   * @module component - OffersSortDropdown
   */
  export default defineComponent({
    name: 'OffersSortDropdown',
    components: {
      CtkSortDropdown
    },
    data () {
      return {
        items: [
          'expires_at', '-expires_at', 'price', '-price', 'pickup_date',
          '-pickup_date', 'delivery_date', '-delivery_date'
        ]
          .map((v) => ({
            value: v,
            text: this.$options.filters.capitalize(this.$t(`offer_list.sort_by.${v}`))
          }))
      }
    },
    computed: {
      ...mapGetters('offers', [
        'getOffersSortType'
      ]),
      /**
       * @function valueText
       * @returns {string} value
       */
      valueText () {
        const sort = this.$t('offer_list.sort_by.' + this.getOffersSortType)
        return this.$options.filters.capitalize(
          this.$t('offer_list.main_content.text_button.sort_offers_by', {
            sort
          })
        )
      }
    },
    methods: {
      ...mapActions('offers', [
        'setOffersSortType'
      ]),
      /**
       * Scroll the content to the top
       * @function scrollTop
       */
      scrollTop () {
        const elem = this.$refs.content
        if (elem) {
          elem.scrollTop = 0
        }
      },
      /**
       * @function sortOffers
       */
      sortOffers (sort) {
        const type = sort[0].value
        this.scrollTop()
        this.setOffersSortType(type)
        Storage && Storage.setItem('sortType', type)

        EventBus.$emit('get-offers')
      }
    }
  })
</script>

<style lang="scss">
.offers-sort-dropdown .ui-select-button-button {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
</style>
