<template>
  <div class="dimension form-group sliders-container mb-4">
    <div class="tw-flex tw-justify-between">
      <div class="tw-w-3/12">
        <dimension-input
          v-if="hasMinimum"
          v-model="tempMinValue"
          :key="minTimestamp"
          :type="type"
          :min="hasMinimum ? 0 : dimension.min"
          :max="dimension.max"
          :interval="dimension.interval"
          :unit="dimension.unit"
          data-test="min"
        />
      </div>
      <label
        :for="type"
        class="tw-text-gray-700 m-0 fs-12 tw-font-normal tw-w-6/12 tw-text-center"
      >
        {{ $t('offer_list.search_engine.' + type + '.label') }}
      </label>
      <dimension-input
        v-model="tempMaxValue"
        :key="maxTimestamp"
        :type="type"
        :min="dimension.min"
        :max="dimension.max"
        :interval="dimension.interval"
        :unit="dimension.unit"
        class="tw-w-3/12"
        data-test="max"
      />
    </div>

    <vue-slider
      ref="slider"
      v-model="sliderValue"
      v-bind="options"
      :min="hasMinimum ? 0 : dimension.min"
      :max="dimension.max"
      :interval="dimension.interval"
      :min-range="hasMinimum ? 1 : null"
      :height="4"
      :dot-size="12"
      :contained="true"
      :dot-options="dotOptions"
      class="slider"
      data-test="slider"
    />
  </div>
</template>

<script>
  import VueSlider from 'vue-slider-component'
  import 'vue-slider-component/theme/default.css'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import DimensionInput from './_subs/DimensionInput'

  export default {
    name: 'Dimension',
    components: {
      VueSlider,
      DimensionInput
    },
    props: {
      type: {
        type: String,
        default: null
      },
      dimension: {
        type: Object,
        required: true
      },
      value: {
        type: Object,
        default: null
      },
      hasMinimum: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { state: tempValue } = useModelGetterSetter(props, 'value')

      return {
        tempValue
      }
    },
    data () {
      /**
       * Quick fix to force the dimension input to be re-rendered if the value is the same
       * but has been "changed".
       * See https://github.com/vuejs/Discussion/issues/356#issuecomment-336060875
       * @const minTimestamp
       * @const maxTimestamp
       */
      return {
        minTimestamp: null,
        maxTimestamp: null
      }
    },
    mounted () {
      /**
       * Adds a negative tabindex attribute to the inner input
       * to remove the "focusability" of this component.
       */
      const inputs = document.querySelectorAll('.vue-slider-sr-only')
      Array.from(inputs).forEach((input) => {
        input.setAttribute('tabindex', '-1')
      })
    },
    computed: {
      options () {
        return {
          railStyle: { backgroundColor: '#E9E9EA' },
          piecewiseStyle: { boxShadow: 'none', width: '40px', height: '20px' },
          dotStyle: { backgroundColor: '#277696', boxShadow: 'none' },
          processStyle: { backgroundColor: '#277696' },
          tooltip: 'none',
          dotOptions: this.dotOptions
        }
      },
      dotOptions () {
        return this.hasMinimum ? [{
          max: this.tempValue.max - 1
        }, {
          min: this.tempValue.min + 1
        }] : []
      },
      sliderValue: {
        get () {
          const min = this.tempValue.min < this.dimension.min
            ? this.dimension.min
            : this.tempValue.min

          const max = this.tempValue.max > this.dimension.max
            ? this.dimension.max
            : this.tempValue.max

          return this.hasMinimum
            ? [min, max]
            : max
        },
        set (v) {
          if (this.hasMinimum) {
            let minValueOverrided = v[0]
            let maxValueOverrided = v[1]

            // Max
            if (v[1] <= this.dimension.min + 10) maxValueOverrided = 10
            if (v[1] < this.dimension.min) maxValueOverrided = this.dimension.min
            if (v[1] > this.dimension.max) maxValueOverrided = this.dimension.max
            if (v[1] <= this.tempMinValue) maxValueOverrided = this.tempMinValue + 10

            // Min
            if (v[0] < this.dimension.min) minValueOverrided = 0
            if (v[0] >= this.tempMaxValue) minValueOverrided = this.tempMaxValue - 10
            if (v[0] > this.dimension.max - 10) minValueOverrided = this.tempMaxValue - 10

            this.tempValue = Object.assign({}, {
              min: minValueOverrided,
              max: maxValueOverrided
            })
          } else {
            this.tempValue = Object.assign({}, {
              min: null,
              max: v
            })
          }
        }
      },
      tempMinValue: {
        get () {
          return this.tempValue.min
        },
        set (v) {
          let valueOverrided = v
          if (v < this.dimension.min) valueOverrided = 0
          if (v >= this.tempMaxValue) valueOverrided = this.tempMaxValue - 10
          if (v > this.dimension.max) valueOverrided = this.tempMaxValue - 10

          this.tempValue = {
            min: valueOverrided,
            max: this.tempValue.max
          }
          this.minTimestamp = Date.now()
        }
      },
      tempMaxValue: {
        get () {
          return this.tempValue.max
        },
        set (v) {
          let valueOverrided = v
          if (v < this.dimension.min) valueOverrided = this.dimension.min
          if (v > this.dimension.max) valueOverrided = this.dimension.max

          if (this.hasMinimum) {
            if (v <= this.tempMinValue) valueOverrided = this.tempMinValue + 10
            /**
             * Same behaviour as the slider, keep a distance of +10 for the max value
             */
            if (v < this.dimension.min + 10) valueOverrided = 10
          }

          this.tempValue = Object.assign({}, {
            max: valueOverrided,
            min: this.tempValue.min
          })
          this.maxTimestamp = Date.now()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .dimension {
    .sliders-container {
      position: relative;

      .dimension-value-container {
        position: absolute;
        right: 0;
        top: -3px;
      }
    }
  }

</style>
