<template>
  <div class="ctk-content-countries tw-flex-col tw-flex tw-flex-1">
    <div class="tw-flex tw-justify-between p-3 checkbox-container">
      <div>
        <h6 class="mb-2 tw-font-normal">
          {{ $t('offer_list.search_engine.geo_selection.map_explanation') }}
        </h6>
        <div class="adjust-item m-0 custom-control custom-checkbox custom-control-inline tw-text-gray-700">
          <input
            :id="'checkbox_' + $parent.selectedCountry"
            v-model="selectAllSelectedCountry"
            type="checkbox"
            autocomplete="off"
            class="custom-control-input"
          >
          <label
            :for="'checkbox_' + $parent.selectedCountry"
            class="custom-control-label"
          >
            {{ $t('offer_list.search_engine.geo_selection.select_all') | capitalize }}
          </label>
        </div>
      </div>
      <div>
        <ui-button
          v-show="hasCountryReset"
          variant="info"
          class="tw-flex tw-items-center animated fadeInRight tw-cursor-pointer tw-rounded-full btn-reset"
          @click="resetSelection"
        >
          <div class="tw-flex tw-items-center">
            <ui-ctk-icon
              name="reset"
              data-test="icon"
              class="tw-text-gray-600"
            />
            <span class="tw-text-gray-600 tw-text-xs">
              {{ $t('offer_list.search_engine.text_button.reset_map') | capitalize }}
            </span>
          </div>
        </ui-button>
      </div>
    </div>
    <div class="tw-h-full svgs-container tw-flex-1 tw-flex tw-flex-row">
      <div class="ctk-content-countries-container tw-flex p-3 tw-w-full">
        <component
          :is="countriesComponents[$parent.selectedCountry]"
          :direction="direction"
          :selected-key="$parent.selectedDivisions[$parent.selectedCountry]"
          :over-id="overId"
          @over-id="changeOverId"
        />
      </div>
      <div
        class="chips-container tw-overflow-auto tw-px-4 tw-pb-4 tw-flex tw-flex-col"
      >
        <ctk-chip
          v-for="division in countriesList[selectedCountry]"
          :key="division"
          :accent="overId === division"
          :chip-id="division"
          :active="checkIfIsActive($parent.selectedDivisions[selectedCountry], division)"
          :title="geonames[division]"
          class="tw-w-full"
          @click.native="toggleAddDivision(division, checkIfIsActive($parent.selectedDivisions[selectedCountry], division))"
          @over-id="changeOverId"
        >
          <template #content>
            <div
              class="dots-text"
            >
              {{ geonames[division] }}
            </div>
          </template>
          <template #avatar>
            <div
              v-if="division.split('-')[1].length <= 2"
            >
              {{ division.split('-')[1] }}
            </div>
          </template>
        </ctk-chip>
      </div>
    </div>
  </div>
</template>

<script>
  import geonames from '@/assets/geo/geonames.json'
  import CountriesList from '@/assets/geo/geo.json'

  import frSVG from './_subs/fr-svg'
  import beSVG from './_subs/be-svg'
  import deSVG from './_subs/de-svg'
  import nlSVG from './_subs/nl-svg'
  import esSVG from './_subs/es-svg'
  import itSVG from './_subs/it-svg'
  import plSVG from './_subs/pl-svg'
  import CtkChip from '@/components/CtkChip'
  import _ from 'underscore'

  export default {
    name: 'ContentCountries',
    components: {
      frSVG, beSVG, deSVG, nlSVG, esSVG, itSVG, plSVG, CtkChip
    },
    props: {
      selectedCountry: {
        type: String,
        default: null
      },
      direction: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        geonames,
        countriesList: CountriesList,
        overId: null,
        countriesComponents: {
          FR: frSVG,
          BE: beSVG,
          DE: deSVG,
          NL: nlSVG,
          ES: esSVG,
          IT: itSVG,
          PL: plSVG
        }
      }
    },
    computed: {
      hasCountryReset () {
        return this.$parent.selectedDivisions[this.selectedCountry].length > 1
      },
      selectAllSelectedCountry: {
        get () {
          const allDivisionsIsSelected = _.isEqual(_.sortBy(this.$parent.selectedDivisions[this.selectedCountry]), _.sortBy(this.countriesList[this.selectedCountry]))
          if (this.$parent.selectedDivisions[this.selectedCountry].length < this.countriesList[this.selectedCountry].length) {
            return false
          } else if (allDivisionsIsSelected && this.$parent.selectedDivisions[this.selectedCountry].length) {
            return true
          } else {
            return false
          }
        },
        set (val) {
          this.toggleSelectAllCountry(val)
        }
      }
    },
    methods: {
      checkIfIsActive (array, val) {
        return array.includes(val)
      },
      changeOverId (val) {
        this.overId = val
      },
      toggleAddDivision (division, isAlreadyActive) {
        if (isAlreadyActive) {
          const index = this.$parent.selectedDivisions[this.selectedCountry].indexOf(division)
          this.$parent.selectedDivisions[this.selectedCountry].splice(index, 1)
        } else {
          this.$parent.selectedDivisions[this.selectedCountry].push(division)
        }
      },
      /**
       * Resets the country selection
       * @function resetSelection
       */
      resetSelection () {
        this.$parent.selectedDivisions[this.selectedCountry] = []
      },
      toggleSelectAllCountry (val) {
        if (val) {
          this.$parent.selectedDivisions[this.selectedCountry] = JSON.parse(JSON.stringify(this.countriesList[this.selectedCountry]))
        } else {
          this.$parent.selectedDivisions[this.selectedCountry] = []
        }
      }
    }
  }
</script>

<style lang="scss">

  .ctk-content-countries {
    &-container {
      min-height: 300px;
    }

    .btn-reset {
      background-color: transparent !important;
      border: none;

      &:active {
        background-color: #CCCDCF !important;

        i,
        span {
          color: rgba(black, 0.87);
        }
      }

      &:focus,
      &:active {
        box-shadow: 0 0 0 0.2rem rgba(189, 189, 189, 0.5) !important;
      }
    }

    .chips-container {
      flex: 0 0 300px;
      align-content: flex-start;
      flex-wrap: wrap;

      .ctk-chip {
        align-self: flex-start;
      }
    }

    /* stylelint-disable */
    @media only screen and (min-width: $breakpoint-tablet) {
      .chips-container {
        @supports(display:grid){
          display: grid;
          grid-template-columns: repeat(2, 50%);
          grid-template-rows: repeat(150, 32px);
          grid-gap: 0 0.5rem;
        }
      }
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      .chips-container {
        @supports(display:grid){
          display: grid;
          grid-template-columns: 100%;
          grid-template-rows: repeat(150, 32px);
          grid-gap: 0 0.5rem;
        }
      }
    }
    /* stylelint-enable */

    .checkbox-container {
      flex: 0 0 85px;
      overflow: hidden;
    }

    .svgs-container {
      @media only screen and (max-width: $breakpoint-laptop-s) {
        flex-direction: column;
      }

      @media only screen and (min-width: $breakpoint-tablet) {
        max-height: 100%;
        max-height: 100vh;
        max-height: calc(100vh - 145px);
      }

      svg {
        max-height: 100%;
        max-height: 100vh;
        max-height: calc(100vh - 145px);

        path.land {
          fill: #CCCDCF;
          stroke: #E9E9EA;
          stroke-width: 0.5;
          transition: fill 200ms;
          cursor: pointer;

          &:hover,
          &.hover {
            fill: darken(#96BF31, 10%) !important;
          }

          &.active {
            fill: #96BF31;
            stroke: #FFF;
          }

          &:focus {
            outline: 0 !important;
          }
        }

        &#IDF {
          path {
            stroke-width: 2;
          }
        }
      }
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      width: 100%;
    }
  }

</style>
