<template>
  <button
    :title="selectTitle"
    :class="direction"
    class="countries-input"
  >
    <div
      :class="direction"
      class="iso-container tw-flex tw-items-center"
    >
      <div class="circle" />
      <div class="countries-input-title">
        {{ title }}
      </div>
    </div>
    <div class="countries-input-list iso-container tw-flex tw-items-center">
      <div
        v-if="isoCodes.length"
        class="vertical-scroll pb-2"
      >
        <ctk-chip
          v-for="(isoCode, key) in divisionsPerCountry"
          :key="key"
          no-round-avatar
        >
          <template #content>
            <div
              class="tw-flex tw-items-center tw--ml-2"
            >
              <ui-flag
                :country="key"
                inline
                data-test="flag"
              />
              <span>
                {{ $t(key) }}
                <span
                  v-if="isoCode[0] !== 'all'"
                  v-text="`(${$n(isoCode.length)})`"
                />
              </span>
            </div>
          </template>
        </ctk-chip>
      </div>
      <div
        v-else
        class="countries-input-list-all tw-text-gray-700"
      >
        {{ $t('all_areas') }}
      </div>
    </div>
  </button>
</template>

<script>
  import CtkChip from '@/components/CtkChip'

  /**
   * @component CountriesInput
   */
  export default {
    name: 'CountriesInput',
    components: {
      CtkChip
    },
    props: {
      title: {
        type: String,
        required: true
      },
      selectTitle: {
        type: String,
        required: true
      },
      isoCodes: {
        type: Array,
        required: true
      },
      direction: {
        type: String,
        required: true
      }
    },
    computed: {
      /**
       * Returns a the list of isoCodes formatted in a way
       * to have a list of divisions per country.
       * @function divisionsPerCountry
       * @returns {Object}
       */
      divisionsPerCountry () {
        const divisions = {}
        this.isoCodes.forEach((key) => {
          if (key.length <= 2) {
            if (!divisions[key]) {
              divisions[key] = []
            }
            divisions[key].push('all')
          } else {
            const splittedDivisions = key.split('-')[0]
            if (!divisions[splittedDivisions]) {
              divisions[splittedDivisions] = []
            }
            divisions[splittedDivisions].push(key)
          }
        })

        return divisions
      }
    }
  }
</script>

<style lang="scss" scoped>

  .countries-input {
    position: relative;
    cursor: pointer;
    width: 100%;
    background: transparent;
    appearance: none;
    border: 1px solid $divider;
    border-radius: 6px;
    outline: none;
    box-shadow: 0;
    transition: box-shadow 200ms;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($info, 0.5);
    }

    &:hover {
      background: #F4F5F5;
    }

    .vertical-scroll {
      display: flex;
      overflow-x: auto;
      margin-left: -5px;
    }

    .circle {
      border-radius: 18px;
      height: 15px;
      width: 15px;
      min-width: 15px;
      border: 2px solid #FFF;
      z-index: 9;
      margin-right: 10px;
    }

    .iso-container {
      min-height: 30px;
      position: relative;
      padding: 5px;

      &:first-child {
        padding-bottom: 0;
      }

      &:last-child {
        padding-top: 0;
        padding-left: 30px;
      }

      &.pickup {
        .circle {
          border-color: $pickup;
        }
      }

      &.delivery {
        .circle {
          border-color: $delivery;
        }
      }
    }
  }

</style>
