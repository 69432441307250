<template>
  <empty-state
    :title="!getCurrentSearch || (getCurrentSearch && !getCurrentSearch.uuid)
      ? $t('offer_list.main_content.no_offer_available')
      : $t('offer_list.main_content.no_offer_available_for_this_search', {
        name: getCurrentSearch.name
      })"
  >
    <ui-button
      v-if="!getCurrentSearch || (getCurrentSearch && !getCurrentSearch.uuid)"
      variant="light"
      data-test="save-search"
      @click="toggleSaveSearchModal"
    >
      {{ $t('offer_list.main_content.text_button.save_search_and_active_alert') }}
    </ui-button>

    <ui-button
      v-if="getCurrentSearch && getCurrentSearch.uuid && !getCurrentSearch.send_by_email"
      variant="light"
      class="tw-flex tw-items-center tw-justify-center"
      data-test="activate-alert"
      @click="toggleSearchAlert"
    >
      <div class="tw-flex tw-items-center">
        <ui-ctk-icon
          name="alert-on"
          class="tw-text-3xl tw-mr-2"
          data-test="icon"
        />
        <div
          v-text="$t('offer_list.main_content.text_button.active_alert')"
        />
      </div>
    </ui-button>
  </empty-state>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import EmptyState from '@/views/Carriers/Offers/components/EmptyState/index.vue'
  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - OffersEmptyState
   */
  export default defineComponent({
    name: 'OffersEmptyState',
    components: {
      EmptyState
    },
    computed: {
      ...mapGetters('offers', [
        'getCurrentSearch'
      ])
    },
    methods: {
      toggleSaveSearchModal () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Freight Searches', 'Initiated Save')
        }

        EventBus.$emit('toggle-save-search-modal')
      },
      toggleSearchAlert () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Searches', 'Created Alert', this.getCurrentSearch.uuid)
        }

        EventBus.$emit('toggle-search-alert')
      }
    }
  })
</script>
