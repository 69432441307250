import geonames from '@/assets/geo/geonames.json'

export default {
  props: {
    selectedKey: {
      type: Array,
      default: null
    },
    overId: {
      type: String,
      default: null
    },
    direction: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      geonames
    }
  },
  methods: {
    onEnter (city) {
      this.$emit('over-id', city.code)
    },
    onLeave () {
      this.$emit('over-id', null)
    },
    onClick (city) {
      if (this.selectedKey.includes(city.code)) {
        const index = this.selectedKey.indexOf(city.code)
        this.selectedKey.splice(index, 1)
      } else {
        this.selectedKey.push(city.code)
      }
    }
  }
}
