<template>
  <nav
    class="tw-flex tw-flex-col main-nav"
    role="navigation"
    @scroll="updateScroll"
  >
    <div class="countries-list tw-flex md:tw-flex-col">
      <country-list-item
        v-for="(sections, country) in countriesList"
        :key="country"
        :class="{
          active: $parent.selectedCountry === country
        }"
        :country="country"
        :sections="sections"
        :selected-sections="$parent.selectedDivisions[country]"
        :disabled="$parent.isCountryDisabled(country)"
        :data-test="`country-list-item-${country}`"
        @click.native.stop="sections.length
          ? selectCountry(country)
          : toggleSelectAllCountry(country)"
      />
    </div>
    <p
      v-if="hasDisabledCountry"
      v-text="`* ${$t('offers.paragraphs.not_allowed_countries')}`"
      class="countries-not-available tw-text-sm tw-mb-0 tw-text-gray-800 tw-whitespace-normal tw-px-4 tw-py-2"
      data-test="not-allowed-countries"
    />

    <transition name="fade">
      <div
        v-if="isShadowLeftVisible"
        class="country-item-shadow left"
      />
    </transition>
    <transition name="fade">
      <div
        v-if="isShadowRightVisible"
        class="country-item-shadow right"
      />
    </transition>
  </nav>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CountriesList from '@/assets/geo/geo.json'
  import CountryListItem from './CountryListItem/index.vue'

  export default defineComponent({
    name: 'SelectCountry',
    components: {
      CountryListItem
    },
    props: {
      direction: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        scrollPosition: 0,
        countriesList: CountriesList
      }
    },
    computed: {
      /**
       * Returns true if there is at least one disabled country in the list
       * @function hasDisabledCountry
       * @returns {boolean}
       */
      hasDisabledCountry () {
        return Object.keys(this.countriesList)
          .some(country => this.$parent.isCountryDisabled(country))
      },
      isShadowLeftVisible () {
        return this.scrollPosition >= 100
      },
      isShadowRightVisible () {
        const nav = document.querySelector('.main-nav')
        return nav ? this.scrollPosition <= (nav.scrollWidth - nav.clientWidth) - 100 : null
      }
    },
    methods: {
      updateScroll (e) {
        const width = document.documentElement.clientWidth
        if (width <= 768) {
          this.scrollPosition = e.target.scrollLeft
        }
      },
      toggleSelectAllCountry (iso) {
        if (this.$parent.isCountryDisabled(iso)) return false

        if (this.$parent.selectedDivisions[iso] === true) {
          this.$parent.selectedDivisions[iso] = []
        } else {
          this.$parent.selectedDivisions[iso] = true
        }
      },
      selectCountry (iso) {
        if (this.$parent.isCountryDisabled(iso)) return false

        this.$parent.selectedCountry = iso
      }
    }
  })
</script>

<style lang="scss" scoped>

  .main-nav {
    $list-height: 50px;

    border-right: 1px solid #F6F6F6;
    overflow: auto;
    flex: 0 0 355px;
    white-space: nowrap;
    z-index: 1;
    box-shadow: 2px 0 10px rgba(black, 0.15);

    @media only screen and (max-width: $breakpoint-tablet) {
      min-height: $list-height;
      width: 100%;
      width: 100vw;
      flex: 0 0 auto;
      overflow: visible;

      .countries-list {
        height: $list-height;
        max-height: $list-height;
        overflow: auto;
      }

      .countries-not-available {
        border-top: 1px solid $divider;
      }
    }

    .country-item-shadow {
      position: absolute;
      height: $list-height;
      width: $list-height;
      background-color: rgba(black, 0.2);
      pointer-events: none;

      &::after {
        position: absolute;
        content: ' ';
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 10px;
        border-color: transparent transparent transparent $secondary;
      }

      &.left {
        left: 0;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0) 100%);

        &::after {
          transform: rotate(180deg);
        }
      }

      &.right {
        right: 0;
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.2) 100%);
      }
    }
  }

</style>
