<template>
  <div class="search-form-countries">
    <b-form-checkbox
      v-model="sameZonesManual"
      :disabled="isSameZonesDisabled"
      :class="{
        'tw-opacity-75': isSameZonesDisabled
      }"
      data-test="same-zones"
      class="search-form-countries__same-zones tw-mb-2"
      @input="updateSameZones"
    >
      {{ $t('offers.labels.same_zones') }}
    </b-form-checkbox>

    <template
      v-if="!sameZonesManual"
    >
      <countries-input
        :title="$t('pickup') | capitalize"
        :select-title="$t('offers.select_pickup')"
        :iso-codes="getCurrentSearch.pickup_iso_codes"
        class="tw-mb-2"
        direction="pickup"
        data-test="pickup-input"
        @click.native="$emit('open-modal', 'pickup')"
      />

      <countries-input
        :title="$t('delivery') | capitalize"
        :select-title="$t('offers.select_delivery')"
        :iso-codes="getCurrentSearch.delivery_iso_codes"
        class="tw-mb-2"
        direction="delivery"
        data-test="delivery-input"
        @click.native="$emit('open-modal', 'delivery')"
      />
    </template>
    <countries-input
      v-else
      :title="$t('offers.labels.pickup_delivery')"
      :select-title="$t('offers.labels.select_pickup_delivery')"
      :iso-codes="getCurrentSearch.pickup_iso_codes"
      class="tw-mb-2"
      direction="pickup"
      data-test="pickup-delivery-input"
      @click.native="$emit('open-modal', 'both')"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import CountriesInput from './../CountriesInput.vue'

  /**
   * @module component - SearchFormCountries
   * @param {boolean} edition - True if we're in the edition mode (coming form the parent component)
   * @emit open-modal
   */
  export default defineComponent({
    name: 'SearchFormCountries',
    components: {
      CountriesInput
    },
    props: {
      edition: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters('offers', [
        'getCurrentSearch',
        'isSameZones'
      ]),
      sameZonesManual: {
        get () {
          return this.isSameZones
        },
        set (v) {
          this.setSameZones(v)
        }
      },
      /**
       * @function isSameZonesDisabled
       * @returns {boolean}
       */
      isSameZonesDisabled () {
        const unauthorizedSameCountries = ['PL']
        const {
          pickup_iso_codes: pickup,
          delivery_iso_codes: delivery
        } = this.getCurrentSearch

        const formattedPickup = (pickup || []).map(v => v.split('-')[0])
        const formattedDelivery = (delivery || []).map(v => v.split('-')[0])

        if (
          unauthorizedSameCountries.some(c => formattedPickup.includes(c)) ||
          unauthorizedSameCountries.some(c => formattedDelivery.includes(c))
        ) return true

        return pickup.length > 0 &&
          delivery.length > 0 &&
          (!pickup.every(p => delivery.includes(p)) || !delivery.every(p => pickup.includes(p)))
      }
    },
    mounted () {
      const {
        pickup_iso_codes: pickup,
        delivery_iso_codes: delivery
      } = this.getCurrentSearch

      this.sameZonesManual = (pickup.length > 0 || delivery.length > 0) &&
        pickup.every(p => delivery.includes(p))
    },
    methods: {
      ...mapActions('offers', [
        'setCurrentSearchIsoCodes',
        'retrieveOffers',
        'setSameZones'
      ]),
      updateSameZones (v) {
        const {
          pickup_iso_codes: pickup,
          delivery_iso_codes: delivery
        } = this.getCurrentSearch

        if (v) {
          if (this.$matomo) {
            this.$matomo.trackEvent(this.edition ? 'Searches' : 'Freight Searches', 'Same Pickup And Delivery')
          }

          /**
           * User wants to use the same pickup/delivery informations
           */
          const usePickup = pickup.length > 0
          for (const key of ['pickup', 'delivery']) {
            this.setCurrentSearchIsoCodes({
              direction: key,
              isoCodes: usePickup ? pickup : delivery
            })
          }

          this.retrieveOffers()
          this.sameZonesManual = true
        } else {
          this.sameZonesManual = false
        }
      }
    }
  })
</script>
