<template>
  <button
    :title="search.send_by_email
      ? $t('offers.searches.disable_alert')
      : $t('offers.searches.enable_alert')"
    :class="{
      active: search.send_by_email
    }"
    type="button"
    class="tw-flex tw-appearance-none tw-border-none tw-cursor-pointer tw-rounded-full tw-p-0 search-item-alert"
    @click.capture="toggle"
  >
    <ui-ctk-icon
      :name="search.send_by_email
        ? 'alert-on'
        : 'alert-off'"
      :class="search.send_by_email ? 'tw-text-green-500' : 'tw-text-gray-700'"
      class="tw-m-auto"
      data-test="icon"
    />
  </button>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import { showToaster } from '@/services/Toaster'

  /**
   * Button component to toggle the alert for a specific search.
   * @component module - SearchItemAlert
   * @param {object} search
   */
  export default defineComponent({
    name: 'SearchItemAlert',
    props: {
      search: {
        type: Object,
        required: true
      }
    },
    methods: {
      toggle () {
        if (this.$wait.is(`deleting ${this.search.uuid} search item`)) return false
        if (this.$wait.is(`toggling search alert for ${this.search.uuid}`)) return false

        this.$wait.start(`toggling search alert for ${this.search.uuid}`)
        this.$store.dispatch('offers/toggleSearchAlert', this.search)
          .then(() => {
            if (this.$matomo) {
              this.$matomo.trackEvent('Searches', 'Updated Alert', this.search.uuid, this.search.send_by_email ? 1 : 0)
            }

            showToaster(
              this,
              this.$t(this.search.send_by_email
                ? 'saved_searches_section.message.alert_enabled'
                : 'saved_searches_section.message.alert_disabled'),
              { type: 'success', position: 'bottom-left' }
            )
          })
          .catch(() => {
            showToaster(this, this.$t('error_while_saving_search'), { type: 'error' })
          })
          .finally(() => {
            this.$wait.end(`toggling search alert for ${this.search.uuid}`)
          })
      }
    }
  })
</script>

<style lang="scss" scoped>

  .search-item-alert {
    background: transparent;
    width: 35px;
    height: 35px;
    margin: auto 4px;
    transition: box-shadow 200ms, background 200ms;

    &:hover {
      background: rgba(black, 0.05);
    }

    &:focus {
      @include focusShadow($info);

      outline: none;
    }
  }

</style>
