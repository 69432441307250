<script>
  import { defineComponent } from '@vue/composition-api'

  import UiSelectButton from '@/components/UI/SelectButton/index.vue'

  /**
   * @module ctkSortDropdown
   * @param {string} [initialValue] - The initial key value. If the selected key is different than
   * this one, we'll add a green circle.
   * @param {Array<any>} items - The dropdown items list
   * @emits input
   */
  export default defineComponent({
    name: 'CtkSortDropdown',
    props: {
      initialValue: {
        type: String,
        default: null
      },
      small: {
        type: Boolean,
        default: false
      },
      value: {
        type: null,
        required: true
      },
      text: {
        type: String,
        required: true
      },
      items: {
        type: Array,
        required: true
      }
    },
    render (h) {
      return h(UiSelectButton, {
        class: ['ctk-sort-dropdown'],
        on: {
          ...this.$listeners
        },
        attrs: {
          ...this.$attrs
        },
        props: {
          icon: 'sort',
          items: this.items,
          value: this.value,
          text: this.text,
          hasDot: this.value[0].value !== this.initialValue
        }
      })
    }
  })
</script>
