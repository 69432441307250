<template>
  <b-row class="tw-flex tw-w-full">
    <b-col class="tw-flex tw-text-center tw-max-h-full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:amcharts="http://amcharts.com/ammap"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        class="tw-flex tw-w-full"
        viewBox="272 0 389.1 312.4"
      >
        <g>
          <path
            v-for="(city, k) in cities"
            :id="city.code"
            :key="k"
            v-b-tooltip.hover
            :class="{
              'active': selectedKey.includes(city.code),
              'hover': overId === city.code
            }"
            :d="city.path"
            :title="city.name"
            class="land"
            @mousemove="onEnter(city)"
            @mouseleave="onLeave(city)"
            @click="onClick(city)"
          />
        </g>
      </svg>
    </b-col>
  </b-row>
</template>

<script>
  import geonames from '@/assets/geo/geonames.json'
  import CountryComponent from '@/mixins/country-component'

  export default {
    name: 'EsSVG',
    mixins: [CountryComponent],
    data () {
      return {
        geonames,
        cities: [
          {
            name: geonames['ES-A'],
            code: 'ES-A',
            path: 'M528.8,203.9l2.9,2.01l3.06,-1.06l3.59,2.85l3.4,-2.21l-2.37,-0.99l0.12,-1.27l3.24,-0.12l5.02,-3.05l2.46,1.1l2.14,-1.33l0.39,1.14l2.38,-1.07l1.12,1.13l-0.11,-1.12l0,0l7.27,3.23l1.01,2.79l-4.22,2.46l-0.35,1.5l-3.42,0.49l-2.1,3.73l-1.57,-0.52l-6.98,3.68l-0.83,3.51l-1.31,-0.41l-2.33,2.86l0.32,3.46l-3.2,1.05l-1.01,6.77l-3.53,5.91l0,0l-4.23,-2.95l-3.99,-6.75l1.93,-6.81L526.96,222l-2.93,-1.01l-0.3,-3.58l2.46,-2.29l-0.37,-6.2l0,0l3.39,-1.58l-1.4,-3.09L528.8,203.9z'
          },
          {
            name: geonames['ES-AB'],
            code: 'ES-AB',
            path: 'M511.12,178.92L512.73,179.69L512.66,181.18L513.91,180.42L516.3,182.24L521.56,183.17L518.44,193.4L522.12,198.18L527.87,197.59L528.8,203.9L528.8,203.9L527.81,204.25L529.21,207.34L525.82,208.92L525.82,208.92L520.98,205.05L516.1,208.06L514.28,207.52L511.33,213.75L511.94,219.82L508.54,222.36L506.06,222.32L505.52,220.24L503.81,219.63L498.77,222.88L494.43,222.57L489.39,226.62L485.45,233.45L485.45,233.45L479.02,231.19L479.02,231.19L482.24,227.27L481.1,218.99L478.4,218.36L477.75,214.58L475.29,215.13L472.55,213.72L472.55,213.72L472.5,210L475.41,209.17L476.36,205.82L473.47,203.97L472.56,200.11L468.85,197.83L471.08,194.47L470.08,190.11L473.75,184.97L473.16,182.87L473.16,182.87L480.26,181.92L484.46,186.24L486.08,184.23L487.08,186.48L490.77,184.33L494.37,184.9L492.98,181.26L495.31,181.54L496.25,183.83L503,184.35z'
          },
          {
            name: geonames['ES-AL'],
            code: 'ES-AL',
            path: 'M489.56,237.69L496.72,239.55L495.55,246.03L496.65,249.2L500.69,255.52L504.01,255.99L507.29,258.6L507.29,258.6L501.97,264.42L499.14,274.96L496.19,277.04L495.87,279.23L492.23,283.19L490.07,283.68L484.87,279.03L482.87,280.22L481.17,279.32L478.59,280.08L474.47,285.1L469.89,284.63L467.57,282.46L461.31,282.5L461.31,282.5L460.95,281.08L464.36,278.92L463.14,276.2L465.97,273.84L464.34,268.64L466.82,269.28L470,262.27L475.68,264.85L476.43,257.85L481.94,253.42L484.94,252.92L483.93,248.67L486.25,249.07L486.89,239.25z'
          },
          {
            name: geonames['ES-AV'],
            code: 'ES-AV',
            path: 'M400.05,110.25L404.19,109.08L408.06,111.79L412.41,109.54L412.41,109.54L412.68,113.05L417.19,117.37L418.25,121.32L417.41,122.09L419.41,122.33L421.34,126.01L421.69,130.4L424.75,129.84L424.75,129.84L426.66,129.6L426.12,128.68L426.12,128.68L429.65,128.13L429.65,128.13L429.61,130.81L427.13,131.66L425.67,130.63L424.72,139.36L421.62,139.53L420.59,143L417.8,142.11L418.33,144.09L416.8,147.11L416.8,147.11L414.5,147.88L413.14,144.51L409.89,144.8L409.78,146.39L405.27,150.96L403.47,151.36L403.31,149.44L398.61,152.41L393.57,151.17L393.57,151.17L392.59,149.24L393.35,145.24L387.59,148.02L381.25,144.04L381.25,144.04L383.23,141.85L382.67,138.91L384.42,137.36L386.67,136.69L386.25,138.01L387.7,138.89L388.23,137.23L389.59,137.14L390.82,133.5L387.78,134.14L388.04,132.34L390.14,132.57L392.13,130.92L392.05,128.87L398.92,123.12L401.12,115.74L399.19,113.02z'
          },
          {
            name: geonames['ES-B'],
            code: 'ES-B',
            path: 'M613.85,62.14l1.86,-0.7l5.12,1.31l-1.63,2.43l1.35,2.26l-1.71,0.65l0.08,-1.32l-1.29,-0.21l1.19,2.05l3.74,1.5l4.42,-1.32l0.76,1.75l2.18,-0.77l1.57,2.33l2.24,-0.1l0.26,2.71l-2.14,1.34l1.6,2.17l-3.02,1.83l-1.68,-0.47l0.72,3.01l2.62,0.29l3.39,3.42l3.39,-1.17l3.66,0.5l0.01,3.48l0,0l-15.68,7.81l-4.7,6.93l-14.35,3.81l0,0l-0.13,-1.61l-2.17,-1.03l1.52,0.29l0.52,-1.72l-1.49,0.08l-1.57,-3.72l-1.96,0.19l0.35,-3.84l-2.71,-1.55l1.78,-2.3l-1.89,-0.24l0,0l0.42,-1.04l-1.44,-0.53l2.78,-1.35l-2.09,-2.42l1.32,-1.11l-0.24,-2.53l1.66,-0.26l-0.31,0.99l1.34,-0.46l0.8,1.45l1.93,-1.04l1.13,-3.32l-1.88,-0.87l2.58,-2l-0.09,-2.09l2.12,-0.2l-0.66,-1.24l-0.01,1.21l-1.52,-0.62l1.58,-1.21l-0.77,-1.22l1.47,-1.31l-0.72,-1.84l1.24,-1.68l-1.8,-1.23l0.73,-1.23l-1.04,-1.88L613.85,62.14z'
          },
          {
            name: geonames['ES-BA'],
            code: 'ES-BA',
            path: 'M413.46,177.64L412.88,182.49L411.21,182.76L414.65,188.92L409.7,187.55L407.73,191.51L409.01,193.59L405.03,193.1L406.39,197.1L409.06,197.94L408.55,200.04L406.49,199.83L405.61,201.13L404.19,205.62L402.44,206.04L402.44,206.04L398.22,206.41L398.2,208.77L394.38,211.98L392.47,211.74L391.02,214.51L386.45,217.6L386.22,221.65L387.89,226.41L385.94,229.35L385.94,229.35L384.44,229.1L382.08,231.31L381.15,229.55L382.88,228.36L382.56,227.22L377.75,227.78L375.45,230.41L375.99,232.02L374.62,234.64L369.66,235.17L367.66,236.74L367.66,236.74L360.49,232.19L359.31,232.29L358.91,234.03L355.32,233.65L354.09,230.69L349.93,230.86L348.16,229.8L348.8,227.53L344.6,226.37L344.6,226.37L339.89,227.89L333.92,218.14L332.2,217.51L335.03,210.15L334.54,206.23L341.3,200.73L340.9,199.07L344,194.48L341.64,190.86L338.08,191.13L338.37,188.67L335.09,187.21L335.38,184.42L335.38,184.42L336.25,184.94L338.86,180.28L340.58,180.73L339.35,181.4L340.01,183.06L341.15,182.52L342.22,180.09L340.45,179.07L340.99,178.18L346.09,180.31L348.58,180.05L350.28,182.73L348.62,185.98L349.64,187.99L353.76,187.66L356.66,189.3L356.61,188.25L361.56,189L363.15,188.06L364.13,191.55L368.83,194.17L372.62,190.33L372.89,193.34L374.98,191.13L375.5,192.14L379.01,189.72L382.7,192.14L385.35,191.45L386.19,189.47L385.06,188L386.98,186.91L391.18,189.24L393.23,185.99L393.45,182.27L396.28,183.51L400.09,182.14L400.39,180.14L402.23,181.12L405.34,179.83L405.34,179.83L405.7,179.83L405.7,179.83L407.84,180.89L411.39,179.01L411.39,179.01z'
          },
          {
            name: geonames['ES-BI'],
            code: 'ES-BI',
            path: 'M472.86,14.14l2.1,3.89l0.06,-1.93l1.18,-0.22l7.08,3.96l0,0l-0.74,3.26l-1.88,0.98l-0.1,5.19l-1.4,0.35l0,0l-3.53,0.58l0.86,2.27l-4.18,-0.29l-3.82,-0.82l-2.42,-2.05l1.14,-1.71l-1.36,-2.75l-2.9,2.33l-2.04,-0.64l0,0l-3.44,-1.55l-5.04,2.73l0,0l-0.95,-4.31l3.57,-2.76l5.37,0.02l0.13,-2.18l0,0l1.52,-0.45l2.61,1.6l-0.56,-1.99l2.81,-1.51l-0.04,-1.13L472.86,14.14zM455.9,22.47l1.69,-0.04l-0.48,2.24l-1.01,-0.02L455.9,22.47z'
          },
          {
            name: geonames['ES-BU'],
            code: 'ES-BU',
            path: 'M470.45,41.99l0.11,0.32l0,0l8.24,0.9l-1.55,2.78l0.96,1.15l1.71,-0.66l0.14,1.57l-7.69,-0.82l-3.09,-3.07l1.01,-2.35l0,0L470.45,41.99zM452.43,27.73l5.04,-2.73l3.44,1.55l0,0l-1.24,1.7l1.47,0.99l-0.24,2.82l-1.1,0.33l3.61,0.77l2.42,2.7l-3.31,1.66l-4.15,-2.12l-1.78,3.1l2.37,2.01l0.46,-1.75l2.32,-0.82l-1.01,5.53l3.02,-0.37l5.88,5.3l0,0l-6.94,-0.1l0.75,2.14l-2.28,1.96l1.92,0.6l-0.7,1.64l1.49,1.78l-0.36,3.11l-1.34,-1.93l1.16,2.51l-1.4,0.17l0.63,4.39l-1.25,1.91l2.92,4.68l3.17,-0.03l0.54,2.63l0,0l0,0l0,0l-3.16,5.59l-2.47,0.24l-2.28,3.61l-3.45,-3.73l0.26,4.72l-2.38,1.26l-3.26,6.07l-2.15,-0.94l-0.38,1.43l0,0l-3.99,0.7l-1.74,1.75l-0.34,2.73l-0.87,-3.6l-1.72,2.36l-4.81,-4.17l0,0l-2.94,-5.84l1.09,-1.73l0,0l-1.47,-4.2l3.32,-1.23l-0.02,-1.24l2.73,-1.1l0.03,-1.61l-4.34,1.75l1.55,-4l-2.99,0.6l-0.71,-2.07l1.44,-0.57l-4.58,-1.39l0.52,-1.72l-1.31,-1.32l-1.27,-7.33l-1.5,0.24l-0.41,-1.66l0.55,-1.62l2.29,0.11l-1.86,-4.11l1.21,-2.6l-0.9,-3.03l6.5,-2.94l0,0l1.09,-0.21l0,0l1.42,-0.1l0,0l5.64,-1.73l-0.64,-2.84l-0.83,-0.46l-0.5,1.6l-1.35,-1.58l2.6,-2.11l-1.47,-0.37l-1.42,2.05l-1.76,-1.3l1.39,-3.13l2.96,-1.48l0.07,-1.81l2.76,-0.03l3.25,-4.02l1.46,1.32L452.43,27.73zM432.55,68.05l0.11,1.32l0,0l-1.52,-1.11l0,0L432.55,68.05z'
          },
          {
            name: geonames['ES-C'],
            code: 'ES-C',
            path: 'M321.35,0l0.84,0.68l-1.38,2.74l0,0l0.5,2.37l-1.36,1.41l-1.37,7.27l-1.32,2.03l-2.08,0.1l0.1,2.61l-1.82,2.11l1.2,14.02l-2.81,4.27l0,0l-1.42,0.73l-4.23,-1.19l-2.94,2.05l-1.84,-1.24l0.23,1.94l-1.85,0.21l-0.45,1.46l-4.59,-0.27l-1.54,1.89l-3.4,0.37l-0.29,1.22l0,0l-2.37,1.67l-1.45,-1.21l-0.18,2.48l-1.34,-1.55l-0.6,2.37l-3.94,2.88l0.41,-1.75l-1.76,-0.68l1.8,-5l4.37,-3.53l0.15,-1.48l-2.22,1.54l-3.2,-0.3l-0.85,2.07l-1.92,-2.72l1.43,-1.17l-2.05,-4.75l-0.42,1.22l-1.37,-1.11l-1.29,2.55l-0.73,-1.73l1.13,-3.3l-1.18,-2.35l4.1,-2v-1.92l-0.81,1.16l-0.66,-1.76l2.34,-1.56l2.39,0.77l1.47,-2.09l2.43,0.58l-1.8,-2.35l4.68,-2.96l3.86,2.38l5.54,-1.08l4.09,-3.16l0.49,2.13l1.1,-0.34l-0.23,-1.78l1.5,-0.65l2.95,3.31l0.69,-4.1l-3.96,-0.85l3.57,-0.65l1.44,-2.21l-1.43,1.48l-4.31,0.83l1.44,-3.17l-0.88,-1.34l1.83,0.38l5.21,-4.68l1.3,1.43l-0.36,-3.05l1.93,-0.01l2.87,-2.68l1.16,-0.22l0.57,2.42l-1.45,2l1.52,0.05l-0.19,-1.33l1.39,0.2l-0.66,-0.56L321.35,0z'
          },
          {
            name: geonames['ES-CA'],
            code: 'ES-CA',
            path: 'M363.04,276.84l13.5,2.16l1.4,-2.79l3.94,-0.16l1.51,-2.16l4.12,3.15l-0.37,-1.41l2.33,-2.05l-0.83,-2.13l2.11,-0.21l-0.33,4.2l4.2,-3.9l2.06,3.68l2.84,-2.4l0,0l1.71,3.78l-1.45,2.54l-5.14,-1.19l-1.2,1.92l1.53,1.69l-1.02,3.88l-4.05,2.05l-2.03,3.34l-2.75,-0.37l0.11,1.12l2.58,0.55l0.96,-1.46l1.86,0.07l3.44,7.95l2.08,0.54l0,0l-2.66,5.91l-3.06,-0.4l0.24,3.59l-5.33,2.29l-2.58,-2.22l-3.4,-0.38l-3.37,-3.94l-3.85,0.08l-8.4,-13.34l1.13,-0.18l1.33,2.82l1.65,-2.26l-2.06,0.6l0.35,-3.08l-4.8,-1.55l-1.56,-3.36l2.73,-3.05l0,0L363.04,276.84z'
          },
          {
            name: geonames['ES-CC'],
            code: 'ES-CC',
            path: 'M346.65,144.98L350.01,146.25L351.15,145.03L352.02,146.1L355.18,144.97L356.74,141.91L366.1,136.31L369.63,138.12L369.22,138.96L371.2,139.93L369.95,141.52L372.7,142.19L374.16,144.22L375.74,144.7L376.75,142.73L379.41,141.67L379.54,144.33L381.25,144.04L381.25,144.04L387.59,148.02L393.35,145.24L392.59,149.24L393.57,151.17L393.57,151.17L392.56,151.8L391.42,160.67L393.86,160.1L395.16,161.22L394.4,165.34L396.08,165.65L398.76,163.77L399.65,167.15L397.54,171.82L405.34,179.83L405.34,179.83L402.23,181.12L400.39,180.14L400.09,182.14L396.28,183.51L393.45,182.27L393.23,185.99L391.18,189.24L386.98,186.91L385.06,188L386.19,189.47L385.35,191.45L382.7,192.14L379.01,189.72L375.5,192.14L374.98,191.13L372.89,193.34L372.62,190.33L368.83,194.17L364.13,191.55L363.15,188.06L361.56,189L356.61,188.25L356.66,189.3L353.76,187.66L349.64,187.99L348.62,185.98L350.28,182.73L348.58,180.05L346.09,180.31L340.99,178.18L340.45,179.07L342.22,180.09L341.15,182.52L340.01,183.06L339.35,181.4L340.58,180.73L338.86,180.28L336.25,184.94L335.38,184.42L335.38,184.42L332.98,181.97L333.43,177.31L330.79,176.02L325.88,169.18L332.26,170.09L342.03,168.92L342.9,163.37L345.46,160.95L346.68,155.32L344.32,151.31L342.11,150.76L341.58,148.35L342.56,146.39z'
          },
          {
            name: geonames['ES-CO'],
            code: 'ES-CO',
            path: 'M402.85,248.31l0.47,1.93l-2.02,-2.16L402.85,248.31zM402.44,206.04l1.3,1.33l3.92,0.35l0.52,2.82l4.69,1.64l7.81,6.68l5.65,2.09l0,0l1.8,1.78l1.5,6.1l-3.71,4.73l-0.14,8.76l2.46,0.69l-0.98,2.28l2.27,1.48l-1.74,2.28l4.95,4.51l-0.85,0.75l2.66,3.23l0,0l-2.61,-0.06l-2.21,2.37l-2.4,0.24l-0.58,4.08l-2.22,1.74l0,0l-1.71,-3.57l-1.86,-0.02l-2.93,2.56l-1.93,0.08l-0.35,-1.76l-1.22,-0.03l0,0l-1.03,-3.28l-2.92,0.49l-1.78,-4.09l-1.37,-0.12l-1.97,-3.23l0.53,-3.68l-1.3,0.67l0.62,-2.03l-1.41,-2.09l-2.23,-0.04l0.06,1.81l-1.81,-1.47l0.67,1.4l-2.11,-0.69l-5.57,3.66l-1.65,-3.78l3.2,-0.61l-0.07,-2.9l-8.43,-13.85l0,0l1.96,-2.94l-1.68,-4.76l0.23,-4.05l4.58,-3.09l1.45,-2.77l1.91,0.24l3.82,-3.21l0.02,-2.36L402.44,206.04z'
          },
          {
            name: geonames['ES-CR'],
            code: 'ES-CR',
            path: 'M405.7,179.83l2.38,-6.37l3.43,2.17l-0.12,3.39l0,0l-3.55,1.88L405.7,179.83zM413.46,177.64l2.13,-0.16l2.85,-4.24l1.96,3.29l0.55,-1.76l6.18,1.79l1.5,-0.69l1.06,-3.22l3.59,0.06l0.36,2.12l-0.91,0.01l-0.26,2.57l-1.53,0.01l-0.92,3.72l5.4,0.42l0.21,2.13l3.32,1.57l1.98,-1.33l2.16,0.89l5.83,-1.36l3.75,-3.97l3.1,0.22l0.46,-2.84l4.63,-0.98l4.42,2.13l2.09,-1.18l0,0l1.88,3.18l-0.7,1.28l1.46,0.37l2.14,-1.8l1.04,3.03l0,0l0.59,2.11l-3.67,5.14l1,4.36l-2.24,3.36l3.71,2.28l0.91,3.86l2.9,1.85l-0.95,3.34l-2.91,0.83l0.05,3.72l0,0l-3.66,3.13l-2.52,-0.73l-1.19,2.47l-1.92,-2.73l-2,1.54l-6.02,-1.69l-3.89,3.2l-3.99,-2l-0.47,2.07l-5.75,-1.04l-3.9,2.19l-10.52,-1.27l-0.41,2.13l0,0l-5.65,-2.09l-7.81,-6.68l-4.69,-1.64l-0.52,-2.82l-3.92,-0.35l-1.3,-1.33l0,0l1.75,-0.42l1.43,-4.49l0.88,-1.3l2.06,0.21l0.51,-2.1l-2.67,-0.84l-1.36,-4l3.98,0.5l-1.28,-2.08l1.97,-3.96l4.95,1.36l-3.44,-6.15l1.67,-0.27L413.46,177.64z'
          },
          {
            name: geonames['ES-CS'],
            code: 'ES-CS',
            path: 'M532.83,160.54l-1.24,-3.81l2.59,-2.8l4.3,-0.93l2.13,-7.26l3.56,0.32l4.47,-4.86l-1.99,-3.16l2.29,-1.19l-0.63,-5.52l-2.37,-0.1l-0.23,-2.17l4.36,-0.95l0.37,-2.54l1.81,-1.39l3.08,2.47l2.47,-0.04l0.81,1.51l3.95,-1.69l0,0l3.72,1.79l-0.4,2.36l3.78,1.11l1.1,2.23l2.37,0.97l0,0l-11.29,17.68l-3,1.86l-1.7,4.81l-5.61,7.66l0,0l-4.28,-3.16l-1.52,0.06l-2.4,3.39l-2.28,-3.23l-1.77,2.16l-1.31,-0.18l-0.77,-3.56l-1.07,-0.66l-1.21,1.49L532.83,160.54z'
          },
          {
            name: geonames['ES-CU'],
            code: 'ES-CU',
            path: 'M463.17,149.51L464.74,149.71L465.06,147.47L467.92,149.52L470.73,147.6L471.97,138.02L473.75,139.91L473.92,138.99L475.05,139.68L475.2,137.86L480.12,138.82L479.76,133.99L480.72,135.42L483.3,134.74L484.28,134.02L483.28,131.67L486.8,132.84L487.51,129.95L489.38,130.98L491.83,129.5L496.38,132.34L497.01,131.29L498.19,131.99L500.34,138.63L501.88,139.86L501.88,139.86L505.21,142.95L505.44,143.41L510.04,148.17L513.15,147.93L512.85,149.99L512.85,149.99L515.06,155.14L521.55,155.39L521.55,155.39L522.25,156.92L522.25,156.92L520.23,158.04L520.49,161.4L518.04,168.62L515.31,168.15L511.15,173.14L511.12,178.92L511.12,178.92L503,184.35L496.25,183.83L495.31,181.54L492.98,181.26L494.37,184.9L490.77,184.33L487.08,186.48L486.08,184.23L484.46,186.24L480.26,181.92L473.16,182.87L473.16,182.87L472.12,179.84L469.99,181.64L468.53,181.26L469.24,179.98L467.36,176.8L467.36,176.8L468.07,170.03L461.35,160.79L462.29,156.32L460.88,156.4L460.3,153.2L460.3,153.2L463.39,152.19L462.28,150.11z'
          },
          {
            name: geonames['ES-GI'],
            code: 'ES-GI',
            path: 'M611.35,54.61l5.39,1.41l2.41,4.43l2.11,-0.61l1.37,-2.14l3.92,-1.03l5.43,1.88l2.85,2.48l1.38,-1.04l3.08,0.74l0.01,-2.6l3.91,-0.6l1.25,-1.7l5.95,-0.64l1.6,2.03l2.71,-0.4l-0.33,3.11l4.82,1.61l-1.7,3.49l-3.12,-1.15l-1.56,2.23l0.37,3.43l2.72,2.43l-0.58,1.85l1.24,2.55l-0.95,2.89l-8.23,7.27l-4.84,2.62l0,0l-0.01,-3.48l-3.66,-0.5l-3.38,1.17l-3.38,-3.42l-2.62,-0.29l-0.72,-3.01l1.68,0.47l3.02,-1.83l-1.6,-2.17l2.14,-1.34l-0.26,-2.71l-2.24,0.1l-1.57,-2.33l-2.18,0.77l-0.76,-1.75l-4.42,1.32l-3.74,-1.5l-1.19,-2.04l1.29,0.21l-0.08,1.32l1.71,-0.65l-1.34,-2.26l1.63,-2.43l-5.12,-1.31l-1.86,0.7l0,0l0.38,-2.62l-1.5,-0.02l-0.14,-2.27l-2.14,-0.64l-0.03,-2.16l0,0L611.35,54.61z'
          },
          {
            name: geonames['ES-GR'],
            code: 'ES-GR',
            path: 'M479.02,231.19L485.45,233.45L485.45,233.45L489.56,237.69L489.56,237.69L486.89,239.25L486.25,249.07L483.93,248.67L484.94,252.92L481.94,253.42L476.43,257.85L475.68,264.85L470,262.27L466.82,269.28L464.34,268.64L465.97,273.84L463.14,276.2L464.36,278.92L460.95,281.08L461.31,282.5L461.31,282.5L454.88,282.96L452.36,284.67L446.76,282.75L441.4,283.02L441.4,283.02L441.68,280.54L439.87,278.5L433.11,276.66L426.2,272.4L424.51,265.91L424.51,265.91L426.73,264.18L427.31,260.1L429.71,259.85L431.93,257.49L434.54,257.55L434.54,257.55L439.44,258.16L441.15,254.13L446.01,252.68L449.71,249.26L453,251.31L458.63,248.22L462.44,250.06L465.92,249.82L468.81,246.79L468.2,244.09L471.85,237.67L477.35,234.55L477.33,232.06z'
          },
          {
            name: geonames['ES-GU'],
            code: 'ES-GU',
            path: 'M458.89,103.25l4.46,1.25l4.76,-2.21l2.59,2.89l3.24,-0.77l2.69,2.46l1.22,-0.38l-1,1.49l1.35,0.35l-0.55,1.13l1.83,-0.45l3.61,4.25l1.95,-1.17l0.98,1.21l2.8,-1.64l1.74,0.54l1.33,-1.39l2.31,1.96l0.15,-3.13l0,0l2.84,-1.04l10.48,9.27l0,0l-0.09,2.6l2.39,2.76l-0.1,8.71l-1.63,1.33l-2.43,-1.16l-0.44,4.19l-3.49,3.56l0,0l-1.54,-1.23l-2.15,-6.65l-1.17,-0.69l-0.63,1.04l-4.55,-2.84l-2.46,1.48l-1.86,-1.03l-0.71,2.9l-3.52,-1.18l1,2.36l-0.98,0.72l-2.58,0.68l-0.96,-1.43l0.36,4.82l-4.92,-0.95l-0.15,1.82l-1.12,-0.69l-0.17,0.91l-1.78,-1.89l-1.25,9.58l-2.81,1.92l-2.85,-2.05l-0.32,2.24l-1.57,-0.2l0,0l-1.28,-5.11l-2.65,1.5l2,-6.31l-2.1,-1.49l-0.06,-2.91l-2.96,-0.91l-0.63,-4.71l-0.66,0.71l-1.67,-2.26l-0.36,0.91l-1.92,-0.79l0.74,-2.87l-2.06,-1.12l3.56,-8.56l-0.96,-3.37l-1.91,-0.43l-1.59,-2.88l0,0l4.21,-1.97l-0.22,-1.91l4.76,0.12L458.89,103.25z'
          },
          {
            name: geonames['ES-H'],
            code: 'ES-H',
            path: 'M343.05,264.73l1.03,1.97l-2.63,-1.61l-11.34,1.08l-1.85,-11.9l-1.81,-2.64l3.11,-7.84l4.23,-4.25l0.65,-4.3l4.35,-0.96l0.68,-1.52l2.97,0.83l2.16,-7.22l0,0l4.19,1.16l-0.64,2.27l1.77,1.07l4.17,-0.17l1.23,2.96l3.59,0.38l0.4,-1.74l1.18,-0.1l7.17,4.56l0,0l1.69,1.4l-1.2,1.63l0.92,2.9l-1.65,-0.85l-1.05,2.71l-2.73,-0.97l-4.99,1.53l-1.91,2.87l0.25,1.36l4.08,0.19l2.72,6.78l-2.39,3.75l1.58,1.48l-0.29,6.76l-1.35,1.86l0.99,3.38l-0.76,1.97l1.47,1.37l0,0l-0.55,3.9l0,0l-1.48,-0.5l-3.78,-6.38L343.05,264.73z'
          },
          {
            name: geonames['ES-HU'],
            code: 'ES-HU',
            path: 'M529.66,44.04L531.3,42.24L532.17,37.46L534.98,36.68L534.98,36.68L539.87,42.43L541.8,40.52L545.26,41.65L547.7,39.68L552.4,41.74L554.05,44.95L556.86,46.45L562.72,44.3L566.36,46.93L568.37,44.83L570.26,46.23L577.56,46.2L577.56,46.2L579.04,49.1L580.86,49.51L580,53.91L578.5,54.64L580.77,61.48L577.48,74.71L574.55,77.32L575.95,78.1L575.94,80.03L571.72,84.4L569.54,84.72L567.38,87.87L568.04,91.21L570.46,91.09L571.02,93.54L567.8,95.92L567.59,99.01L567.59,99.01L564.83,98.18L560.17,101.38L557.61,100.96L556.57,99.47L557.06,101.47L555.79,100.96L552.51,91.59L550.99,91.09L549.76,92.26L546.19,89.64L545.82,87.58L541.37,85.89L541.45,81.49L537.12,76.53L533.43,76.53L530.15,74.63L532.61,70.81L532.92,72.51L533.9,71.98L534.94,62.5L534.71,59.52L533.44,58.63L532.36,63.88L530.4,63.17L530.02,64.86L529.14,64.25L532.29,58.48L529.52,56.76L529.91,53.72L528.66,53.88L529.79,52.47L528.64,51.11L529.54,49.94L528.84,47.64L530.3,46.31L529,45.79z'
          },
          {
            name: geonames['ES-J'],
            code: 'ES-J',
            path: 'M472.55,213.72L475.29,215.13L477.75,214.58L478.4,218.36L481.1,218.99L482.24,227.27L479.02,231.19L479.02,231.19L477.33,232.06L477.35,234.55L471.85,237.67L468.2,244.09L468.81,246.79L465.92,249.82L462.44,250.06L458.63,248.22L453,251.31L449.71,249.26L446.01,252.68L441.15,254.13L439.44,258.16L434.54,257.55L434.54,257.55L431.89,254.32L432.73,253.57L427.78,249.06L429.52,246.78L427.25,245.3L428.23,243.03L425.77,242.34L425.9,233.57L429.61,228.84L428.12,222.74L426.32,220.96L426.32,220.96L426.72,218.83L437.25,220.11L441.15,217.92L446.89,218.96L447.36,216.9L451.35,218.9L455.23,215.7L461.26,217.39L463.26,215.85L465.17,218.58L466.37,216.12L468.89,216.85z'
          },
          {
            name: geonames['ES-L'],
            code: 'ES-L',
            path: 'M579.23,39.17l6.5,2.92l4.64,0.07l2.85,3.32l5.75,-0.52l2.58,5.05l-0.87,2.64l1.7,1.03l-1.54,1.12l0.95,1.9l3.15,0.19l0.26,-1.05l2.95,-0.42l0.4,-1.47l1.86,0.49l0,0l0.03,2.16l2.14,0.64l0.14,2.27l1.5,0.02l-0.38,2.62l0,0l-5.29,1.02l1.04,1.88l-0.73,1.23l1.8,1.23l-1.24,1.68l0.72,1.84l-1.46,1.31l0.77,1.22l-1.58,1.21l1.52,0.62l0.01,-1.21l0.66,1.24l-2.12,0.2l0.09,2.09l-2.58,2l1.88,0.87l-1.13,3.32l-1.93,1.04l-0.79,-1.45l-1.34,0.46l0.31,-0.99l-1.66,0.26l0.24,2.54l-1.32,1.11l2.09,2.42l-2.78,1.35l1.44,0.53l-0.42,1.04l0,0l-5.31,0.23l-1.69,1.44l0.5,1.89l-2.11,0.86l-2.22,-1.39l1.09,1.68l-3.4,4.26l-3.1,0.71l-0.57,-0.97l-0.77,1.35l-5.87,1.54l-2.26,-1.45l-1.57,1.5l-1.29,-1.84l-0.86,2.4l-1.46,-0.11l0,0l-1.56,-5.27l0,0l0.22,-3.09l3.22,-2.38l-0.56,-2.46l-2.42,0.12l-0.66,-3.34l2.16,-3.14l2.18,-0.32l4.22,-4.38l0.01,-1.93l-1.4,-0.78l2.93,-2.61l3.29,-13.23l-2.27,-6.84L580,53.91l0.86,-4.4l-1.81,-0.41l-1.48,-2.9l0,0l-0.05,-6.07L579.23,39.17z'
          },
          {
            name: geonames['ES-LE'],
            code: 'ES-LE',
            path: 'M408.76,25.74l-0.32,2.3l3.51,4.39l0,0l-5.03,6.83l0.16,3.01l-1.24,1.7l1.98,1.52l-1.34,6.14l0.37,7.2l-2.13,0.5l0.75,2.63l-1.66,0.9l0,0l-1.11,-0.33l-0.5,1.64l-1.75,-2.24l-2.17,1.97l-1.65,-0.38l-0.15,2.43l-4.5,-0.29l0.36,7.42l0,0l-1.39,0.44l0,0l-3.05,-2.74l0,0l-2.02,1.9l-2.48,-3.75l-5.44,1.29l-1.61,-1.87l-1.72,1.34l-2.31,-1.4l-5.63,0.39l-3.07,-2.41l-5.68,1.01l-6.33,-1.13l-2.47,-1.83l0,0l1.55,-4.36l-3.36,-1.93l0.64,-3.52l-3.36,-1.23l-1.44,1.13l-3.01,-0.62l0,0l0.92,-5.17l1.31,-0.42l-1.25,-2.16l5.46,-3.85l0.83,-1.51l-0.95,-2.1l1.73,0.13l-0.29,-1.85l0,0l2.07,1.34l2.02,-1.64l6.17,0.09l1.74,-0.81l-1.36,-2.18l1.61,0.18l2.02,-3.01l4.06,2.12l0.4,-1.58l3.59,0.67l0.46,-1.89l3.31,0.45l1.83,3.02l3.39,1.26l3.4,-3.92l6.13,1.47l0.47,-1.4l2.7,0.23l0.65,-1.89l8.21,-0.3l0.79,-3.34l2.42,0.35l2.59,-2.68L408.76,25.74zM393.23,64.09l1.35,0.3l0,0l-0.99,0.87L393.23,64.09z'
          },
          {
            name: geonames['ES-LO'],
            code: 'ES-LO',
            path: 'M467.9,73.84L467.36,71.21L464.2,71.24L461.28,66.56L462.53,64.65L461.9,60.26L463.3,60.09L462.14,57.58L463.48,59.51L463.84,56.4L462.34,54.62L463.05,52.98L461.13,52.38L463.41,50.43L462.67,48.29L469.6,48.39L469.6,48.39L470.78,49.38L470.62,51.85L472.5,49.03L475.05,50.21L474.29,53.43L475.2,53.06L476.06,54.82L477.36,53.98L477.62,55.25L479.68,54.79L480.29,53.3L480.14,54.54L483.03,54.57L483.03,54.57L485.32,56.14L487.1,55.48L489.3,57.62L492.52,57.46L493.18,60.47L496.07,59.78L499.9,62.86L499.84,64.35L505.71,66.85L505.77,68.53L501.41,68.45L499.23,71.28L498.96,73.56L500.63,74.45L500.63,74.45L500.34,76.16L500.34,76.16L496.49,78.1L492.56,76.51L490.97,72.03L492.03,70.78L487.66,71.13L485.74,68.77L480.14,70.06L478.14,74.93L473.04,74.42L474.27,70.56L473.28,69.64L471.65,70.3L471,73.27z'
          },
          {
            name: geonames['ES-LU'],
            code: 'ES-LU',
            path: 'M322.93,1.62l1.35,3.55l2.05,-2.84l5.2,2.73l3.43,4.04l-0.65,1.51l0.91,-1.21l6.01,0.52l-0.34,3.48l0,0l-1.54,2l-2.15,-0.14l-0.28,1.62l1.68,0.82l0.81,2.88l1.27,-0.05l-0.06,2.57l2.78,1.67l0.42,2.88l2.93,-1.96l1.24,1.64l-5.39,5.18l3.85,1.28l1.46,3.09l0,0l0.29,1.85l-1.73,-0.12l0.95,2.1l-0.83,1.51l-5.45,3.85l1.25,2.16l-1.31,0.42l-0.92,5.17l0,0l-3.85,7.56l-0.53,-2.54l-2.67,-1.75l-9.5,1.87l-5.29,-3.39l0.75,-1.15l-4.56,-0.61l0.71,-1.73l-1.25,-1.18l0,0l2.13,-5.3l-3.59,-3.03l0.68,-2.53l-1.33,-0.38l0,0l2.81,-4.27l-1.19,-14.02l1.82,-2.11l-0.1,-2.61l2.08,-0.1l1.32,-2.03l1.37,-7.27l1.36,-1.41l-0.5,-2.37l0,0L322.93,1.62z'
          },
          {
            name: geonames['ES-M'],
            code: 'ES-M',
            path: 'M426.12,128.68l0.54,0.92l-1.91,0.24l0,0L426.12,128.68zM448.69,108.91l1.59,2.88l1.91,0.43l0.96,3.37l-3.56,8.56l2.06,1.12l-0.74,2.87l1.92,0.79l0.36,-0.91l1.67,2.26l0.66,-0.71l0.63,4.71l2.96,0.91l0.06,2.91l2.1,1.5l-2,6.31l2.65,-1.5l1.28,5.11l0,0l-0.89,0.6l1.11,2.08l-3.09,1.01l0,0l-0.42,-1.03l-3.12,1.74l-1.77,-1.36l-1.83,1.84l-3.46,-0.51l-9.11,6.53l-2.21,-1.76l3.7,-0.85l4.13,-4.23l0.43,-2.11l-1.85,-1.38l-2.4,0.44l-1.87,-1.79l-4.4,-0.46l-3.74,-3.03l-2.22,0.79l-1.46,-2.08l-4,2.88l-1.83,-3.73l-1.73,3.23l-3.02,1.53l-1.32,-0.71l0,0l1.53,-3.02l-0.53,-1.98l2.79,0.89l1.03,-3.47l3.1,-0.17l0.95,-8.74l1.46,1.03l2.49,-0.85l0.03,-2.68l0,0l2.77,-4.19l2.77,0.39l2.6,-7.39l3.48,-1.35l5.16,-6.04L448.69,108.91z'
          },
          {
            name: geonames['ES-MA'],
            code: 'ES-MA',
            path: 'M414.52,263.17l1.22,0.03l0.35,1.76l1.93,-0.08l2.93,-2.56l1.86,0.02l1.71,3.57l0,0l1.69,6.49l6.91,4.26l6.76,1.84l1.81,2.05l-0.28,2.48l0,0l-18.95,0.54l-7.49,8.25l-9.07,0.23l-7.46,3.27l-2.28,3.91l0,0l-2.08,-0.54l-3.44,-7.94l-1.86,-0.07l-0.96,1.46l-2.58,-0.55l-0.11,-1.11l2.75,0.37l2.03,-3.34l4.05,-2.05l1.02,-3.88l-1.53,-1.69l1.2,-1.92l5.14,1.19l1.45,-2.54l-1.71,-3.78l0,0l7.18,-4.24l-0.96,-2.34l1.97,0.67l1.52,-1.63l0.97,1.81l2.63,-1.69l-0.76,-2.01L414.52,263.17z'
          },
          {
            name: geonames['ES-MU'],
            code: 'ES-MU',
            path: 'M485.45,233.45l3.93,-6.83l5.05,-4.05l4.34,0.3l5.03,-3.25l1.72,0.6l0.54,2.08l2.47,0.04l3.41,-2.54l-0.61,-6.07l2.95,-6.23l1.82,0.54l4.88,-3.01l4.85,3.87l0,0l0.37,6.2l-2.46,2.29l0.3,3.58l2.93,1.01l0.66,1.88l-1.93,6.81l3.99,6.75l4.23,2.95l0,0l-2.99,4.95l4.06,3.27l0.3,1.07l-6.19,2.04l-4.76,-1.16l-1.11,1.93l-3.18,-1.59l-3.41,0.48l-4.13,2.95l-0.67,2.36l-4.54,1.92l0,0l-3.28,-2.61l-3.32,-0.47l-4.04,-6.32l-1.1,-3.17l1.17,-6.48l-7.15,-1.86l0,0L485.45,233.45z'
          },
          {
            name: geonames['ES-NA'],
            code: 'ES-NA',
            path: 'M506.68,20.31L507.96,22.74L510.2,20.98L514.83,22.68L514.88,25.39L512.09,29.69L513.08,31.38L515.77,32.14L516.04,29.35L518.33,28.36L517.15,30.41L518.53,31.46L520.11,31.16L528.31,35.25L534.24,34.68L534.98,36.68L534.98,36.68L532.17,37.46L531.3,42.24L529.66,44.04L529.66,44.04L529.66,44.04L529.66,44.04L525.76,45.82L524.99,48.22L521.94,48L520.4,52.07L518.5,51.77L517.78,53.18L518.25,54.86L516.14,57.28L516.52,60.12L514.38,62.77L513.81,66.05L514.38,69.58L517.27,73.01L514.02,78.11L509.49,78.32L500.63,74.45L500.63,74.45L498.96,73.56L499.23,71.28L501.41,68.45L505.77,68.53L505.71,66.85L499.84,64.35L499.9,62.86L496.07,59.78L493.18,60.47L492.52,57.46L489.3,57.62L487.1,55.48L485.32,56.14L483.03,54.57L483.03,54.57L483.76,50.02L482.03,51.34L480.59,49.29L483.18,47.37L484.22,48.55L487.08,47.69L486,44.82L487.88,43.81L488.24,37.67L488.24,37.67L489.48,35.4L491.96,35.84L494.76,34.1L495.24,30.58L498.94,27.64L498.51,23.97L500.22,24.42L504.25,20.9L504.25,20.9z'
          },
          {
            name: geonames['ES-O'],
            code: 'ES-O',
            path: 'M378.11,5.51l4.52,4.99l8.69,-0.47l3.39,0.79l2.57,2.62l8.86,0.46l12.71,2.92l0,0l-0.34,4.84l-2.55,-0.94l-0.78,1.38l-3.04,0.43l-0.23,2.98l-3.15,0.24l0,0l-2.19,-2.35l-2.59,2.68l-2.42,-0.35l-0.79,3.34l-8.2,0.31l-0.65,1.89l-2.7,-0.23l-0.47,1.4l-6.12,-1.47l-3.4,3.93l-3.39,-1.26l-1.83,-3.02l-3.31,-0.45l-0.46,1.89l-3.59,-0.67l-0.4,1.58l-4.06,-2.12l-2.02,3.01l-1.6,-0.18l1.36,2.18l-1.74,0.81l-6.17,-0.08l-2.01,1.64l-2.07,-1.33l0,0l-1.46,-3.09l-3.85,-1.28l5.39,-5.18l-1.24,-1.63l-2.93,1.96l-0.42,-2.88l-2.78,-1.67l0.06,-2.57l-1.27,0.05l-0.81,-2.88l-1.68,-0.82l0.28,-1.61l2.15,0.14l1.54,-2l0,0l1.39,-2.34L341.6,9.9l2.56,-0.75l18.12,1.02l3.41,-1.95l3.93,1.78l4.75,-0.95L378.11,5.51z'
          },
          {
            name: geonames['ES-OR'],
            code: 'ES-OR',
            path: 'M313.95,50.87L315.2,52.05L314.49,53.78L319.05,54.39L318.3,55.54L323.59,58.93L333.09,57.05L335.76,58.8L336.29,61.35L340.14,53.79L340.14,53.79L343.15,54.41L344.59,53.28L347.95,54.51L347.31,58.03L350.67,59.96L349.13,64.33L349.13,64.33L349.04,65.89L345.98,65.95L341.48,71.72L343.28,72.51L343.01,75.89L343.01,75.89L340.41,76.88L338.85,75.33L337.04,75.6L336.47,79.71L331.28,80.83L329.38,82.69L328.62,80.32L323.74,81.8L324.53,79.64L321,78.58L316.57,79.66L315.72,81.15L314.69,77.78L314.24,79.8L307.8,82.63L305.16,78.35L309.23,72.83L308.18,71.39L306.23,72.1L305.7,68.43L305.7,68.43L306.86,64.72L303.7,64.36L303.79,61.79L301.86,60.55L300.58,54.65L302.53,54.54L306.49,51.14L311.32,52.36z'
          },
          {
            name: geonames['ES-P'],
            code: 'ES-P',
            path: 'M432.22,67.86l0.43,1.5l0,0l-1.52,-1.11l0,0L432.22,67.86zM433.16,43.05l1.3,-2.7l0.12,2.6l0,0L433.16,43.05zM411.95,32.43l5.48,0.06l3.4,-1.63l3.08,3.61l3.34,0.74l0.6,4.34l2.29,-0.75l0.48,0.93l-1.66,1.5l3.11,2.04l0,0l-6.5,2.94l0.9,3.03l-1.21,2.6l1.86,4.11l-2.29,-0.11l-0.55,1.62l0.41,1.66l1.5,-0.24l1.27,7.33l1.31,1.32l-0.51,1.72l4.58,1.39l-1.44,0.57l0.71,2.07l2.99,-0.6l-1.55,4l4.34,-1.75l-0.03,1.61l-2.73,1.1l0.02,1.24l-3.32,1.23l1.47,4.2l0,0l-5.99,-1.87l-2.5,2.07l-2.27,-0.98l-1.92,1.15l-1.44,-0.5l0.86,-2.94l-2.85,1.25l-3.18,-3.61l-3.67,3.39l-2.45,-4.67l-3.52,0.89l-0.66,-1.53l3.1,-5.4l-0.72,-2.96l-3.05,-0.15l0.76,-5.58l0,0l1.66,-0.9l-0.75,-2.63l2.13,-0.5l-0.37,-7.2l1.34,-6.14l-1.98,-1.52l1.24,-1.69l-0.16,-3.01L411.95,32.43z'
          },
          {
            name: geonames['ES-PO'],
            code: 'ES-PO',
            path: 'M289.52,46.77l0.29,-1.22l3.4,-0.37l1.54,-1.89l4.59,0.27l0.45,-1.46l1.85,-0.21l-0.23,-1.94l1.84,1.24l2.94,-2.05l4.23,1.19l1.42,-0.73l0,0l1.33,0.38l-0.68,2.53l3.6,3.03l-2.13,5.3l0,0l-2.63,1.49l-4.83,-1.22l-3.95,3.39l-1.95,0.12l1.28,5.89l1.93,1.24l-0.08,2.57l3.15,0.36l-1.16,3.71l0,0l-4.06,2.9l-9.33,1.48l-7.34,7.29l-0.77,-9.95l2.63,-0.11l-1.09,-1.71l1.09,-1.34l6.16,-3.98l-0.56,-2.81l-1.76,3.06l-5.63,1.22l0.49,-2.43l1.25,1.26l-0.63,-2.55l2.06,0.32l3.5,-3.85l-5.6,1.74l-1.32,-2.97l-1.93,-0.33l2.27,-1.55l0.09,2.23l1.46,-0.22l-0.26,-5.1L289.52,46.77z'
          },
          {
            name: geonames['ES-S'],
            code: 'ES-S',
            path: 'M456.11,24.66l-0.21,-2.18l1.69,-0.04l-0.48,2.24L456.11,24.66zM447.28,11.69l4.85,2.38l-0.31,0.94l-1.56,-0.49l0.78,2.4l0.54,-1.3l3.74,0.08l0.11,1.27l2.98,-0.29l2.14,1.83l0,0l-0.12,2.18l-5.37,-0.02l-3.57,2.76l0.95,4.31l0,0l-5.7,-0.68l-1.46,-1.32l-3.25,4.02l-2.76,0.03l-0.07,1.81l-2.96,1.48l-1.39,3.13l1.76,1.3l1.42,-2.05l1.47,0.38l-2.6,2.11l1.36,1.58l0.51,-1.6l0.83,0.46l0.64,2.85l-5.63,1.73l0,0l-0.12,-2.6l-1.3,2.7l0,0l-1.09,0.21l0,0l-3.11,-2.04l1.66,-1.5l-0.48,-0.93l-2.29,0.76l-0.6,-4.33l-3.34,-0.74l-3.07,-3.61l-3.4,1.63l-5.48,-0.06l0,0l-3.51,-4.39l0.32,-2.3l0,0l3.15,-0.23l0.23,-2.98l3.04,-0.43l0.78,-1.38l2.55,0.94l0.34,-4.84l0,0l8.94,-0.02l13.41,-4.14l0.65,1.02l-1.76,0.81l0.19,1.97l1.31,-1.96l1.05,0.73l0.42,-1.75L447.28,11.69z'
          },
          {
            name: geonames['ES-SA'],
            code: 'ES-SA',
            path: 'M358.47,103.66L363.45,104.6L369.07,107.88L371.29,106.63L372.92,107.47L372.52,109.3L373.83,108.52L374.63,109.92L376.33,105.92L380.4,106.84L383.72,105.63L388.99,108.34L389.25,108.37L391.37,108.07L391.6,109.7L393.79,110.85L395.02,108.22L395.02,108.22L396.44,107.6L400.05,110.25L400.05,110.25L399.19,113.02L401.12,115.74L398.92,123.12L392.05,128.87L392.13,130.92L390.14,132.57L388.04,132.34L387.78,134.14L390.82,133.5L389.59,137.14L388.23,137.23L387.7,138.89L386.25,138.01L386.67,136.69L384.42,137.36L382.67,138.91L383.23,141.85L381.25,144.04L381.25,144.04L379.54,144.33L379.41,141.67L376.75,142.73L375.74,144.7L374.16,144.22L372.7,142.19L369.95,141.52L371.2,139.93L369.22,138.96L369.63,138.12L366.1,136.31L356.74,141.91L355.18,144.97L352.02,146.1L351.15,145.03L350.01,146.25L346.65,144.98L346.65,144.98L349.23,141.22L347.12,137.72L348.79,135.32L347.25,133.12L348.83,129.28L347.69,125.59L348.64,121.72L344.61,114.41L348.37,114.12L349.63,110.13L353.29,105.55L355.66,105.9z'
          },
          {
            name: geonames['ES-SE'],
            code: 'ES-SE',
            path: 'M367.66,236.74l1.99,-1.58l4.96,-0.53l1.37,-2.62l-0.54,-1.61l2.3,-2.62l4.81,-0.57l0.32,1.15l-1.73,1.19l0.93,1.77l2.36,-2.22l1.49,0.25l0,0l8.43,13.85l0.07,2.9l-3.2,0.61l1.65,3.78l5.57,-3.66l2.11,0.7l-0.67,-1.4l1.81,1.47l-0.06,-1.81l2.23,0.04l1.41,2.09l-0.62,2.03l1.3,-0.67l-0.53,3.68l1.97,3.23l1.37,0.12l1.78,4.09l2.92,-0.49l1.03,3.29l0,0l-2.46,0.22l0.76,2.01l-2.63,1.7l-0.97,-1.81l-1.52,1.63l-1.97,-0.67l0.96,2.34l-7.18,4.24l0,0l-2.84,2.4l-2.06,-3.68l-4.2,3.91l0.33,-4.2l-2.11,0.21l0.83,2.13l-2.33,2.05l0.37,1.41l-4.12,-3.15l-1.51,2.16l-3.94,0.17l-1.4,2.79l-13.5,-2.16l0,0l-1.47,-1.37l0.76,-1.97l-0.99,-3.37l1.35,-1.86l0.29,-6.76l-1.58,-1.47l2.39,-3.75l-2.71,-6.78l-4.08,-0.18l-0.25,-1.36l1.91,-2.87l5,-1.53l2.73,0.97l1.05,-2.71l1.65,0.85l-0.92,-2.9l1.2,-1.63L367.66,236.74zM403.32,250.23l-2.02,-2.16l1.55,0.24L403.32,250.23z'
          },
          {
            name: geonames['ES-SG'],
            code: 'ES-SG',
            path: 'M435.16,91.86L439.97,96.04L441.69,93.68L442.56,97.28L442.9,94.55L444.64,92.8L448.63,92.1L448.63,92.1L449.85,94.08L452.23,94.59L452.57,96.24L455.11,96.87L454.49,99.56L458.89,103.25L458.89,103.25L457.43,105.14L452.68,105.02L452.9,106.93L448.69,108.91L448.69,108.91L446.42,109.55L441.26,115.59L437.78,116.94L435.19,124.33L432.42,123.94L429.65,128.13L429.65,128.13L426.12,128.68L426.12,128.68L424.75,129.84L424.75,129.84L421.69,130.4L421.34,126.01L419.41,122.33L417.41,122.09L418.25,121.32L417.19,117.37L412.68,113.05L412.41,109.54L412.41,109.54L413.68,108.06L415.3,108.67L414.59,106.62L416.96,104.43L416.25,102.58L420.21,103.59L418.77,98.96L432.56,95.48L434.57,94.61z'
          },
          {
            name: geonames['ES-SO'],
            code: 'ES-SO',
            path: 'M467.9,73.84L471,73.27L471.65,70.3L473.28,69.64L474.27,70.56L473.04,74.42L478.14,74.93L480.14,70.06L485.74,68.77L487.66,71.13L492.03,70.78L490.97,72.03L492.56,76.51L496.49,78.1L500.34,76.16L500.34,76.16L501.53,80.8L500.45,83.42L502.48,87.12L498.3,91.25L496.14,91.18L497.84,99.05L495.04,99.99L494.84,98.25L492.82,97.48L490.93,101.17L491.45,108.12L494.35,109.64L494.35,109.64L494.2,112.77L491.89,110.81L490.56,112.2L488.81,111.67L486.01,113.31L485.03,112.09L483.08,113.26L479.47,109.01L477.65,109.45L478.2,108.33L476.85,107.97L477.85,106.49L476.63,106.87L473.94,104.41L470.71,105.18L468.11,102.29L463.35,104.5L458.89,103.25L458.89,103.25L454.49,99.56L455.11,96.87L452.57,96.24L452.23,94.59L449.85,94.08L448.63,92.1L448.63,92.1L449.01,90.67L451.16,91.61L454.42,85.54L456.8,84.28L456.54,79.56L459.99,83.28L462.27,79.67L464.74,79.43z'
          },
          {
            name: geonames['ES-SS'],
            code: 'ES-SS',
            path: 'M502.31,16.81L502.13,18.77L504.04,19.49L504.25,20.9L504.25,20.9L500.22,24.42L498.51,23.97L498.94,27.64L495.24,30.58L494.76,34.1L491.96,35.84L489.48,35.4L488.24,37.67L488.24,37.67L485.05,35.4L478.11,34.8L477.63,32.92L479.83,31.45L479.16,29.62L479.16,29.62L480.56,29.27L480.66,24.08L482.54,23.1L483.28,19.84L483.28,19.84L492.03,21.13z'
          },
          {
            name: geonames['ES-TE'],
            code: 'ES-TE',
            path: 'M507.68,117.88L512.11,117.67L512.91,116.55L512,114.99L514.19,112.91L515.7,112.01L515.75,113.27L517.8,113.62L518.81,110.63L522.89,110.13L523.41,108.8L525.42,110.14L527.47,109.38L526.67,111.74L527.93,112.5L530.2,109.57L530.92,111.22L533.11,111.22L533.94,108.72L536.01,111.05L538.59,107.82L536.77,105.99L538.12,105.53L537.98,102.94L538.73,102.47L541.67,105.93L539.45,102.55L539.89,101.22L542.58,101.55L544.85,104.54L556.52,110.4L558.44,113.71L559.53,112.38L564.07,112.7L564.07,112.7L566.31,116.79L564.64,120.25L565.85,122.84L562.55,126.4L562.55,126.4L558.6,128.08L557.79,126.57L555.32,126.62L552.25,124.15L550.43,125.54L550.07,128.08L545.71,129.04L545.94,131.2L548.31,131.31L548.94,136.83L546.65,138.03L548.64,141.19L544.17,146.05L540.61,145.74L538.48,153L534.18,153.94L531.6,156.73L532.83,160.54L532.83,160.54L530.04,161.69L529.06,157.22L522.25,156.92L522.25,156.92L521.55,155.39L521.55,155.39L524.41,153.38L522.1,151.25L519.11,151.16L517.47,147.34L515.66,150.65L512.85,149.99L512.85,149.99L513.15,147.93L510.04,148.17L505.44,143.41L505.21,142.95L501.88,139.86L501.88,139.86L505.37,136.3L505.81,132.11L508.25,133.28L509.88,131.94L509.98,123.23L507.59,120.48z'
          },
          {
            name: geonames['ES-TO'],
            code: 'ES-TO',
            path: 'M416.8,147.11L418.12,147.82L421.14,146.3L422.87,143.07L424.7,146.8L428.69,143.92L430.16,146L432.38,145.21L436.12,148.24L440.52,148.71L442.39,150.5L444.79,150.06L446.64,151.45L446.21,153.56L442.07,157.79L438.37,158.64L440.58,160.4L449.69,153.87L453.16,154.38L454.99,152.54L456.76,153.9L459.88,152.17L460.3,153.2L460.3,153.2L460.88,156.4L462.29,156.32L461.35,160.79L468.07,170.03L467.36,176.8L467.36,176.8L465.27,177.98L460.85,175.85L456.22,176.83L455.77,179.67L452.66,179.45L448.91,183.42L443.08,184.79L440.92,183.89L438.94,185.22L435.63,183.66L435.42,181.52L430.02,181.1L430.94,177.38L432.48,177.38L432.73,174.81L433.64,174.8L433.28,172.69L429.69,172.63L428.64,175.86L427.14,176.55L420.95,174.76L420.4,176.52L418.44,173.24L415.6,177.48L413.46,177.64L413.46,177.64L413.46,177.64L413.46,177.64L411.39,179.01L411.39,179.01L411.51,175.62L408.08,173.45L405.7,179.83L405.7,179.83L405.34,179.83L405.34,179.83L397.54,171.82L399.65,167.15L398.76,163.77L396.08,165.65L394.4,165.34L395.16,161.22L393.86,160.1L391.42,160.67L392.56,151.8L393.57,151.17L393.57,151.17L398.61,152.41L403.31,149.44L403.47,151.36L405.27,150.96L409.78,146.39L409.89,144.8L413.14,144.51L414.5,147.88z'
          },
          {
            name: geonames['ES-T'],
            code: 'ES-T',
            path: 'M600.05,92.2l1.89,0.24l-1.78,2.3l2.71,1.55l-0.35,3.84l1.96,-0.19l1.58,3.72l1.49,-0.08l-0.52,1.72l-1.52,-0.29l2.17,1.03l0.13,1.61l0,0l-13.22,3.74l-1.29,1.98l-1.51,-0.82l-4.29,1.45l-8.71,9.59l5.36,2.84l-0.15,1.45l-6.11,5.46l-2.28,-0.65l3.37,-0.82l1.18,-1.58l-4.4,0.57l-2.63,3.99l0,0l-2.37,-0.97l-1.1,-2.23l-3.78,-1.11l0.4,-2.36l-3.72,-1.79l0,0l3.3,-3.56l-1.2,-2.58l1.67,-3.46l-2.24,-4.09l0,0l-0.59,-2.24l3.13,-1.53l2.53,-4.65l0,0l1.46,0.11l0.86,-2.4l1.29,1.84l1.57,-1.5l2.26,1.45l5.88,-1.54l0.78,-1.35l0.57,0.97l3.1,-0.71l3.4,-4.26l-1.09,-1.68l2.22,1.39l2.11,-0.86l-0.5,-1.89l1.69,-1.43L600.05,92.2z'
          },
          {
            name: geonames['ES-V'],
            code: 'ES-V',
            path: 'M522.25,156.92l6.81,0.3l0.98,4.47l2.79,-1.15l0,0l2.06,2.63l1.21,-1.49l1.07,0.66l0.77,3.57l1.31,0.19l1.77,-2.16l2.28,3.23l2.4,-3.39l1.52,-0.06l4.28,3.16l0,0l-3.91,7.88l-0.55,3.96l3.63,9.35l-0.64,2.32l1.52,3.61l4.6,5.92l0,0l0.11,1.12l-1.12,-1.12l-2.38,1.07l-0.39,-1.14l-2.14,1.33l-2.46,-1.1l-5.02,3.05l-3.24,0.12l-0.12,1.27l2.37,1l-3.4,2.21l-3.59,-2.85l-3.06,1.06l-2.89,-2.01l0,0l-0.93,-6.31l-5.75,0.6l-3.69,-4.78l3.12,-10.23l-5.26,-0.93l-2.39,-1.83l-1.26,0.76l0.07,-1.49l-1.61,-0.76l0,0l0.03,-5.78l4.17,-4.99l2.73,0.47l2.44,-7.22l-0.25,-3.36L522.25,156.92zM512.85,149.99l2.82,0.66l1.81,-3.31l1.64,3.82l3,0.09l2.31,2.13l-2.85,2.01l0,0l-6.5,-0.25L512.85,149.99z'
          },
          {
            name: geonames['ES-VA'],
            code: 'ES-VA',
            path: 'M387.9,70.78l3.05,2.74l0,0l-0.51,2.6l-1.43,-0.71L387.9,70.78zM393.23,64.09l1.35,0.3l0,0l-1.04,0.87L393.23,64.09zM392.33,73.07l-0.36,-7.42l4.5,0.29l0.15,-2.43l1.65,0.38l2.17,-1.97l1.76,2.25l0.51,-1.64l1.11,0.33l0,0l-0.76,5.58l3.05,0.15l0.72,2.96l-3.1,5.4l0.66,1.53l3.52,-0.89l2.45,4.67l3.67,-3.38l3.18,3.61l2.85,-1.25l-0.85,2.94l1.44,0.5l1.92,-1.15l2.27,0.98l2.51,-2.07l5.99,1.87l0,0l-1.09,1.73l2.94,5.84l0,0l-0.59,2.75l-2.01,0.87l-13.79,3.47l1.44,4.63l-3.96,-1l0.71,1.85l-2.36,2.19l0.71,2.06l-1.62,-0.61l-1.27,1.48l0,0l-4.34,2.24l-3.87,-2.71l-4.15,1.17l0,0l-3.61,-2.64l-1.42,0.62l0,0l-1.58,-0.49l1.49,-3.05l-1.63,-8.56l3.56,-2.01l-1.97,-1.88l0.69,-1.09l-1.79,-0.6l0.02,-2.69l-1.94,-3.22l3.43,-2.17l-1.53,-0.73l1.1,-3.65l-1.27,-0.72l2.47,-1.82L392.33,73.07z'
          },
          {
            name: geonames['ES-VI'],
            code: 'ES-VI',
            path: 'M460.91,26.55l2.05,0.64l2.9,-2.33l1.36,2.75l-1.14,1.71l2.42,2.05l3.82,0.82l4.18,0.29l-0.86,-2.27l3.53,-0.58l0,0l0.67,1.83l-2.2,1.47l0.48,1.88l6.94,0.59l3.19,2.27l0,0l-0.36,6.15l-1.88,1l1.08,2.87l-2.87,0.86l-1.04,-1.18l-2.59,1.92l1.44,2.05l1.73,-1.33l-0.73,4.56l0,0l-2.89,-0.03l0.15,-1.24l-0.62,1.5l-2.06,0.46l-0.26,-1.27l-1.3,0.84l-0.86,-1.76l-0.91,0.37l0.77,-3.23l-2.55,-1.18l-1.88,2.82l0.16,-2.47l-1.17,-0.98l0,0l-5.88,-5.3l-3.01,0.37l1.01,-5.52l-2.32,0.83l-0.46,1.75l-2.37,-2.01l1.78,-3.1l4.15,2.12l3.32,-1.66l-2.42,-2.7l-3.61,-0.77l1.1,-0.33l0.24,-2.82l-1.47,-0.99L460.91,26.55zM470.56,42.31l8.24,0.9l-1.55,2.78l0.96,1.15l1.71,-0.66l0.14,1.57l-7.69,-0.82l-3.09,-3.07l1.01,-2.35l0,0L470.56,42.31z'
          },
          {
            name: geonames['ES-Z'],
            code: 'ES-Z',
            path: 'M529.66,44.04L529,45.79L530.3,46.31L528.84,47.64L529.54,49.94L528.64,51.11L529.79,52.47L528.66,53.88L529.91,53.72L529.52,56.76L532.29,58.48L529.14,64.25L530.02,64.86L530.4,63.17L532.36,63.88L533.44,58.63L534.71,59.52L534.94,62.5L533.9,71.98L532.92,72.51L532.61,70.81L530.15,74.63L533.43,76.53L537.12,76.53L541.45,81.49L541.37,85.89L545.82,87.58L546.19,89.64L549.76,92.26L550.99,91.09L552.51,91.59L555.79,100.96L557.06,101.47L556.57,99.47L557.61,100.96L560.17,101.38L564.83,98.18L567.59,99.01L567.59,99.01L569.14,104.28L569.14,104.28L566.62,108.93L563.48,110.46L564.07,112.7L564.07,112.7L559.53,112.38L558.44,113.71L556.52,110.4L544.85,104.54L542.58,101.55L539.89,101.22L539.45,102.55L541.67,105.93L538.73,102.47L537.98,102.94L538.12,105.53L536.77,105.99L538.59,107.82L536.01,111.05L533.94,108.72L533.11,111.22L530.92,111.22L530.2,109.57L527.93,112.5L526.67,111.74L527.47,109.38L525.42,110.14L523.41,108.8L522.89,110.13L518.81,110.63L517.8,113.62L515.75,113.27L515.7,112.01L514.19,112.91L512,114.99L512.91,116.55L512.11,117.67L507.68,117.88L507.68,117.88L497.19,108.6L494.35,109.64L494.35,109.64L491.45,108.12L490.93,101.17L492.82,97.48L494.84,98.25L495.04,99.99L497.84,99.05L496.14,91.18L498.3,91.25L502.48,87.12L500.45,83.42L501.53,80.8L500.34,76.16L500.34,76.16L500.63,74.45L500.63,74.45L509.49,78.32L514.02,78.11L517.27,73.01L514.38,69.58L513.81,66.05L514.38,62.77L516.52,60.12L516.14,57.28L518.25,54.86L517.78,53.18L518.5,51.77L520.4,52.07L521.94,48L524.99,48.22L525.76,45.82z'
          },
          {
            name: geonames['ES-ZA'],
            code: 'ES-ZA',
            path: 'M349.13,64.33L351.6,66.15L357.93,67.28L363.6,66.27L366.68,68.68L372.31,68.29L374.62,69.69L376.34,68.35L377.96,70.22L383.4,68.93L385.88,72.69L387.9,70.78L387.9,70.78L389,75.42L390.43,76.12L390.94,73.52L390.94,73.52L392.33,73.07L392.33,73.07L396.06,75.54L393.59,77.35L394.87,78.07L393.77,81.72L395.3,82.44L391.87,84.61L393.81,87.84L393.79,90.53L395.58,91.14L394.89,92.23L396.86,94.11L393.3,96.12L394.93,104.67L393.44,107.73L395.02,108.22L395.02,108.22L393.79,110.85L391.6,109.7L391.37,108.07L389.25,108.37L388.99,108.34L383.72,105.63L380.4,106.84L376.33,105.92L374.63,109.92L373.83,108.52L372.52,109.3L372.92,107.47L371.29,106.63L369.07,107.88L363.45,104.6L358.47,103.66L358.47,103.66L361.54,101.13L361.27,99.94L363.46,99.9L362.96,98.93L367.15,91.61L362.67,88.18L359.54,87.72L357.9,88.85L356.37,87.68L355.73,85.31L357.37,80.06L355.66,79.57L356.42,77.27L355.25,76.17L351.71,77.52L348.29,75.09L347.71,77.1L344.3,77.09L343.01,75.89L343.01,75.89L343.28,72.51L341.48,71.72L345.98,65.95L349.04,65.89z'
          }
        ]
      }
    }
  }
</script>
