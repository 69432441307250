<template>
  <div
    class="offers tw-flex tw-flex-1"
  >
    <search-side-bar
      ref="searchSideBar"
      :open="isSidebarOpen"
      :width="350"
      @open-countries-dialog="handleDialogCountries"
      @save-search="toggleSaveSearchModal"
    />

    <router-view
      :class="{
        'has-menu-sidebar': isSidebarOpen,
      }"
      class="main-container tw-flex-1"
    />

    <dialog-save-search
      :show-modal="saveModal"
      @close-modal="toggleSaveSearchModal(false)"
      @save-search="saveSearchByDialog"
    />

    <dialog-iso-codes
      v-show="isoDialogVisible"
      :direction="isoDialogDirection"
      @selection-done="selectedDivisons => selectionDivisions(isoDialogDirection, selectedDivisons)"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import { EventBus } from '@/services/EventBus'
  import store from '@/store'
  import SearchSideBar from './components/SearchSideBar/index.vue'
  import DialogIsoCodes from '@/views/Carriers/Offers/components/DialogIsoCodes/index.vue'
  import DialogSaveSearch from '@/views/Carriers/Offers/components/DialogSaveSearch/index.vue'

  // @ts-ignore
  import CountriesList from '@/assets/geo/geo.json'
  import { showToaster } from '@/services/Toaster'

  export default defineComponent({
    name: 'OffersRouter',
    components: {
      SearchSideBar,
      DialogIsoCodes,
      DialogSaveSearch
    },
    data () {
      return {
        saveModal: false,
        isoDialogDirection: 'pickup',
        isoDialogVisible: false,
        offerNotAvailableDialog: {
          showDialog: false,
          offerType: true
        }
      }
    },
    computed: {
      ...mapGetters('ui', [
        'isSidebarOpen'
      ]),
      ...mapGetters('auth', [
        'getUserInfos'
      ]),
      ...mapGetters('offers', [
        'hasCurrentOfferUuid'
      ]),
      /**
       * @function isDetailOpen
       * @returns {boolean}
       */
      isDetailOpen () {
        const { name } = this.$route

        return this.hasCurrentOfferUuid || (this.hasCurrentOfferUuid) || (!this.hasCurrentOfferUuid && name !== 'Proposals')
      }
    },
    mounted () {
      // @ts-ignore
      EventBus.$on('toggle-save-search-modal', (v) => {
        this.toggleSaveSearchModal(v)
      })
      EventBus.$on('toggle-search-alert', () => {
        this.toggleSearchAlert()
      })

      this.retrieveOffersMetrics()
    },
    // @ts-ignore
    beforeRouteEnter (to, from, next) {
      if (store.getters['auth/isAccountLocked']) {
        next({
          name: 'MissionsRouter'
        })
        return
      }

      if (store.getters.isUserShipper) {
        next({
          name: 'Dashboard'
        })
        return
      }

      next()
    },
    beforeDestroy () {
      const events = ['get-offers', 'toggle-save-search-modal', 'toggle-search-alert']
      events.forEach((e) => {
        EventBus.$off(e)
      })
    },
    methods: {
      ...mapActions('offers', [
        'setCurrentSearch', 'resetCurrentOffer', 'setKeyboardStatus', 'saveSearch',
        'setOffersSuggestionsVisibility', 'retrieveOffers', 'getOffers', 'retrieveOffersMetrics',
        'refreshSearchesCount'
      ]),
      /**
       * @function toggleSearchAlert
       */
      toggleSearchAlert () {
        this.$store.dispatch('offers/toggleSearchAlert')
          .then(() => {
            showToaster(this, this.$t(this.getCurrentSearch.send_by_email
              ? 'saved_searches_section.message.alert_enabled'
              : 'saved_searches_section.message.alert_disabled'
            ), {
              type: 'success',
              position: 'bottom-left'
            })
          })
          .catch(() => {
            showToaster(this, this.$t('error_while_saving_search'), { type: 'error' })
          })
      },
      toggleSaveSearchModal (modifyAlert) {
        if (modifyAlert) {
          this.$store.commit('offers/SET_CURRENT_SEARCH_ATTRIBUT', { key: 'send_by_email', val: true })
        }
        this.saveModal = !this.saveModal
      },
      handleDialogCountries (direction) {
        this.isoDialogDirection = direction
        this.isoDialogVisible = true
        this.setKeyboardStatus(false)
      },
      offerNotAvailable (type) {
        this.offerNotAvailableDialog.showDialog = true
        this.offerNotAvailableDialog.offerType = type
        this.getOffers({ pageNumber: null })
          .catch(() => {})

        setTimeout(() => {
          this.offerNotAvailableDialog.showDialog = false
        }, 20000)
      },
      saveNewSearch (search) {
        this.saveSearch(search)
          .then(({ data }) => {
            const searchData = {
              ...search,
              uuid: data.uuid
            }

            this.setCurrentSearch({ search: searchData })
            EventBus.$emit('offers:set-search-active', false)

            this.$router.push({
              name: 'Searches',
              params: {
                uuid: searchData.uuid
              }
            })
              .catch(() => {})
          })
          .catch(() => {
            showToaster(this, this.$t('error_while_saving_search'), { type: 'error' })
          })
          .finally(() => {
            this.refreshSearchesCount()
          })
      },
      saveSearchByDialog (search) {
        if (this.$matomo) {
          this.$matomo.trackEvent('Freight Searches', 'Confirmed Save')
        }

        this.toggleSaveSearchModal()
        this.saveNewSearch(search)
      },
      selectionDivisions (direction, selectedDivisons) {
        this.isoDialogVisible = false
        this.setKeyboardStatus(true)
        if (selectedDivisons) {
          /**
           * If the direction is "both", loop on pickup & delivery to set them the same
           * isoCodes.
           */
          const directions = direction === 'both' ? ['pickup', 'delivery'] : [direction]
          for (const dir of directions) {
            this.$store.commit('offers/SET_CURRENT_SEARCH_ISO_CODES', { direction: dir, isoCodes: [] })
            /**
             * TODO: Use a store action instead of directly commit
             */
            Object.keys(selectedDivisons).forEach((value) => {
              if (selectedDivisons[value] === true) {
                this.$store.commit('offers/ADD_ISO_CODES_IN_CURRENT_SEARCH', { direction: dir, isoCode: value })
              } else if (selectedDivisons[value].length > 0 && (selectedDivisons[value].length === CountriesList[value].length)) {
                this.$store.commit('offers/ADD_ISO_CODES_IN_CURRENT_SEARCH', { direction: dir, isoCode: value })
              } else if (selectedDivisons[value] !== true) {
                selectedDivisons[value].forEach((division) => {
                  this.$store.commit('offers/ADD_ISO_CODES_IN_CURRENT_SEARCH', { direction: dir, isoCode: division })
                })
              }
            })
          }

          this.retrieveOffers({ fromFilter: true })
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
.offers .main-container {
  transition: padding-left 300ms ease-in-out;
}
@media (min-width: 770px) {
  .offers .main-container:not(.has-menu-sidebar) {
    padding-left: 2rem;
  }
}
</style>
