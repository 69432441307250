<template>
  <div class="country-zoomed-section">
    <div class="tw-flex tw-items-center tw-justify-center tw-mb-3">
      <b-form-checkbox
        v-model="selectAll"
        :id="title"
        :name="title"
        class="tw-mr-2"
      >
        {{ title }}
      </b-form-checkbox>
    </div>
    <svg
      class="tw-flex tw-mx-auto country-zoomed-section__map"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      :viewBox="`0 0 ${width} ${height}`"
      xml:space="preserve"
    >
      <g>
        <path
          v-for="(section, k) in sections"
          :id="section.code"
          :key="k"
          v-b-tooltip.hover
          :d="section.path"
          :title="section.name"
          :class="{
            'active': selectedKey.includes(section.code),
            'hover': overId === section.code
          }"
          class="land"
          @mousemove="onEnter(section)"
          @mouseleave="onLeave(section)"
          @click="onClick(section)"
        />
      </g>
    </svg>
  </div>
</template>

<script>
  import CountryComponent from '@/mixins/country-component'

  /**
   * @module component - CountryZoomedSection
   */
  export default {
    name: 'CountryZoomedSection',
    props: {
      title: {
        type: String,
        required: true
      },
      width: {
        type: String,
        required: true
      },
      height: {
        type: String,
        required: true
      },
      sections: {
        type: Array,
        required: true
      }
    },
    mixins: [CountryComponent],
    computed: {
      selectAll: {
        get () {
          return this.sections
            .every(section => this.selectedKey.includes(section.code))
        },
        set (v) {
          this.sections.forEach(section => {
            if (v) {
              if (!this.selectedKey.includes(section.code)) {
                this.selectedKey.push(section.code)
              }
            } else {
              if (this.selectedKey.includes(section.code)) {
                const index = this.selectedKey.indexOf(section.code)
                this.selectedKey.splice(index, 1)
              }
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .country-zoomed-section {
    &__map {
      height: 100% !important;
      height: calc(100% - 32px) !important;
      max-width: 100% !important;
      max-height: calc(100% - 37px) !important;
    }
  }

</style>
