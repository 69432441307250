<template>
  <span
    :id="chipId"
    :style="sideBarStyles"
    :class="{ 'is-hover': accent, 'is-active': active, 'has-avatar': $slots.avatar, 'has-right': $slots.right }"
    class="ctk-chip"
    @mouseenter="onEnter($event)"
    @mouseleave="onLeave"
  >
    <div
      v-if="$slots.avatar"
      :class="{ 'ctk-avatar': !noRoundAvatar }"
    >
      <slot name="avatar" />
    </div>
    <slot
      name="content"
    />
    <div
      v-if="$slots.right"
      class="ctk-avatar right"
    >
      <slot name="right" />
    </div>
  </span>
</template>

<script>
  export default {
    name: 'CtkChip',
    props: {
      active: {
        type: Boolean,
        required: false,
        default: false
      },
      accent: {
        type: Boolean,
        required: false,
        default: false
      },
      chipId: {
        type: String,
        required: false,
        default: null
      },
      bgColor: {
        type: String,
        required: false,
        default: null
      },
      textColor: {
        type: String,
        required: false,
        default: null
      },
      noRoundAvatar: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    computed: {
      sideBarStyles () {
        return {
          backgroundColor: this.noRoundAvatar ? 'transparent' : this.bgColor,
          color: this.textColor,
          borderRadius: this.noRoundAvatar ? '0' : '28px'
        }
      }
    },
    methods: {
      onEnter (event) {
        const elemId = event.currentTarget.id
        this.$emit('over-id', elemId)
      },
      onLeave () {
        this.$emit('over-id', null)
      }
    }
  }
</script>

<style lang="scss" scoped>

  span.ctk-chip {
    font-size: 12px;
    margin: 4px;
    outline: none;
    height: 23px;
    line-height: 23px;
    position: relative;
    background: #FFF;
    color: rgba(0, 0, 0, 0.87);
    padding: 0 8px;
    cursor: pointer;
    display: inline-block;
    transition: 0.3s;
    white-space: nowrap;

    &.has-avatar {
      padding: 0 4px 0 25px;
    }

    &.has-right {
      padding-right: 25px;
    }

    .ctk-avatar {
      width: 23px;
      height: 23px;
      background: #CCCDCF;
      position: absolute;
      left: -1px;
      border-radius: 25px;
      text-align: center;
      transition: 0.3s;
      color: $primary-text;

      &.right {
        left: inherit;
        right: -1px;
      }

      &.is-active {
        background: $primary;
      }

      &.is-hover {
        background: darken($primary, 10%);
      }
    }

    &.is-hover,
    &.is-active {
      background-color: $gray;

      .ctk-avatar {
        color: white;
      }
    }
  }


</style>
