<template>
  <div
    v-infinite-scroll="loadMoreOffers"
    class="offers-list-content tw-flex-1 tw-overflow-y-auto"
    infinite-scroll-distance="120"
  >
    <div class="tw-mb-4">
      <ctk-offer-card
        v-for="offer in getOffersList"
        :key="offer.uuid"
        :ref="getCurrentOffer && offer.uuid === getCurrentOffer.uuid
          ? 'currentItem'
          : false"
        :offer="offer"
        have-proposal-triangle
        @click.native="showOffer(offer)"
      />
    </div>

    <ctk-infinite-loader
      :loaded-text="$t('all_offers_are_loaded') | capitalize"
      :load-more-text="$t('load_more_offers') | capitalize"
      :is-loading="$wait.is('loading more offers')"
      :can-load-more="canLoadMore"
      :items-count="getOffersList.length"
      @load-more="loadMoreOffers"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import CtkOfferCard from '@/components/CtkOfferCard/index.vue'
  import CtkInfiniteLoader from '@/components/CtkInfiniteLoader/index.vue'
  import { EventBus } from '@/services/EventBus'

  import KeyboardOfferNavigation from '@/mixins/keyboard-offer-navigation'

  /**
   * Offers listing component, used in list mode and map mode, inside the
   * sidebar component.
   * @module component - offersListContent
   */
  export default defineComponent({
    name: 'OffersListContent',
    components: {
      CtkInfiniteLoader,
      CtkOfferCard
    },
    mixins: [KeyboardOfferNavigation],
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('offers', [
        'getOffersList',
        'getCurrentOffer'
      ]),
      /**
       * Returns true if we can fetch more offers
       * @function canLoadMore
       * @returns {boolean}
       */
      canLoadMore () {
        const { offerPagination } = this.$store.state.offers
        const { pagination } = this.$store.state.offers.currentOffersMeta
        return offerPagination < pagination.page_count
      }
    },
    methods: {
      ...mapActions('offers', [
        'setCurrentOffer',
        'trackOffer'
      ]),
      loadMoreOffers () {
        this.$emit('load-more-offers')
      },
      /**
       * @function showOffer
       * @param {{ uuid: string }} offer
       */
      showOffer (offer) {
        const { uuid } = offer
        EventBus.$emit('layout-update')

        this.setCurrentOffer(offer)

        // TODO: Use the Matomo directive instead of duplicating code.
        if (this.$matomo) {
          this.$matomo.trackEvent('Offers', 'Clicked', uuid)
        }

        this.trackOffer(uuid)
      }
    }
  })
</script>
