<template>
  <ctk-list-header
    :title="title"
    :subtitle="$tc('offer_list.offer_number', count, {
      count: $n(count)
    })"
    class="offers-list-header tw-px-4 flex-fixed"
  >
    <div class="offers-list-header__actions tw-flex tw-mt-3 tw-mb-3 md:tw-mb-0">
      <offers-sort-dropdown
        class="tw-w-full"
        data-test="sort"
      />
    </div>

    <template
      #additional-actions
    >
      <ui-button
        v-if="$route.name === 'Offers'"
        :rounded="false"
        type="button"
        variant="info"
        class="offers-list-header__filter tw-my-auto tw-w-full md:tw-w-auto md:tw-mr-4 tw-flex-shrink-0 tw-mb-2 md:tw-mb-auto"
        data-test="filter"
        @click="filter"
      >
        <template #left-icon>
          <ui-ctk-icon
            name="adjust"
            data-test="icon"
          />
        </template>
        <div
          v-text="$t('offers.buttons.filter_offers')"
        />
      </ui-button>
    </template>
  </ctk-list-header>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import { EventBus } from '@/services/EventBus'
  import CtkListHeader from '@/components/CtkList/_subs/CtkListHeader/index.vue'
  import OffersSortDropdown from '@/views/Carriers/Offers/components/OffersList/_subs/OffersListHeader/_subs/OffersSortDropdown/index.vue'

  /**
   * @module component - OffersListHeader
   */
  export default defineComponent({
    name: 'OffersListHeader',
    components: {
      CtkListHeader,
      OffersSortDropdown
    },
    computed: {
      ...mapGetters('offers', [
        'getCurrentSearch'
      ]),
      /**
       * Returns the appropriate title according to the current route and
       * the search value
       * @function title
       * @returns {import('vue-i18n').TranslateResult}
       */
      title () {
        const searchRoutes = ['Searches']
        if (searchRoutes.includes(this.$route.name)) {
          if (this.getCurrentSearch && this.getCurrentSearch.name) return this.getCurrentSearch.name
          else return this.$t('offer_list.searches')
        } else {
          return this.$t('offer_list.fret_offer_number')
        }
      },
      /**
       * @function count
       * @returns {number}
       */
      count () {
        return this.$store.state.offers.currentOffersMeta.item_count
      }
    },
    methods: {
      filter () {
        EventBus.$emit('offers:filter')
      }
    }
  })
</script>

<style lang="scss" scoped>

  .offers-list-header {
    &::after {
      left: 1rem;
      width: calc(100% - 2rem);
    }
  }

</style>
