import { mapActions, mapGetters } from 'vuex'

/**
 * Vue mixin to inject the required methods, events to handle the offers navigation
 * with the keyboard. This functionnality is used twice so a mixin was required.
 * @module mixin - keyboardOfferNavigation
 */
export default {
  computed: {
    ...mapGetters('offers', [
      'isKeyboardActive',
      'getCurrentOffer',
      'getSortedOffersList',
      'hasCurrentOfferUuid'
    ])
  },
  methods: {
    ...mapActions('offers', [
      'trackOffer',
      'setNextOffer',
      'setPreviousOffer',
      'setCurrentOffer',
      'resetCurrentOffer'
    ]),
    /**
     * Function called whenever a key is pressed
     * @function keyPressed
     * @param {object} e - Event object
     */
    async keyPressed (e) {
      if (this.isKeyboardActive) {
        try {
          if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
            const offer = await this.setPreviousOffer()
            if (offer && this.$matomo) {
              this.$matomo.trackEvent('Offers', 'Clicked Previous', offer.uuid)
            }
          } else if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
            /**
             * If there is no current offer open, set the first offer by default
             * Set the next one otherwise.
             */
            let offer = this.getSortedOffersList[0]
            if (!this.hasCurrentOfferUuid) {
              this.setCurrentOffer(offer)
              this.trackOffer(offer.uuid)
            } else {
              offer = await this.setNextOffer()
            }
            if (offer && this.$matomo) {
              this.$matomo.trackEvent('Offers', 'Clicked Next', offer.uuid)
            }

            /**
             * Unfocus everything
             */
            if ('activeElement' in document) document.activeElement.blur()
          }
        } catch (err) {
          console.error('An error occured while switching offers', e)
        }

        this.scrollToOffer()
      }
    },
    /**
     * Scroll to the current selected offer item in the list
     * @function scrollToOffer
     */
    scrollToOffer () {
      this.$nextTick(() => {
        try {
          if (this.$refs.currentItem && this.$refs.currentItem[0]) {
            const item = this.$refs.currentItem[0]
            /**
             * Scroll the view to the current item
             */
            try {
              item.$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              })
            } catch (error) {
              // fallback to prevent browser crashing
              item.$el.scrollIntoView(false)
            }
          }
        } catch (e) {
          console.error('Could not scroll to the current item', e)
        }
      })
    }
  },
  mounted () {
    window.addEventListener('keydown', this.keyPressed)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.keyPressed)
  }
}
