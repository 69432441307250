var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ctk-side-bar',{ref:"searchSideBar",staticClass:"search-side-bar",attrs:{"open":_vm.isOpen,"width":350,"without-close-btn":_vm.searchActive},on:{"toggle-menu":_vm.toggleMenu}},[_c('ctk-collapse-category-item',{attrs:{"title":_vm.$t('offers.titles.freight_search'),"icon":'search'}}),_c('ctk-collapse-nav-item',{staticClass:"freight-search-item tw-mb-8",class:{
      'router-link-active': ['Offers', 'Searches'].includes(_vm.$route.name) && !_vm.$route.params.uuid
    },attrs:{"to":{
      name: 'Offers'
    },"title":_vm.$t('offers.titles.all_offers'),"count":_vm.getOffersMetrics.total,"has-dot":_vm.getOffersMetrics.unviewed_item_count > 0,"dot-tooltip":_vm.getOffersMetrics.unviewed_item_count > 0
      ? _vm.$tc('offers.values.unviewed_offers', _vm.getOffersMetrics.unviewed_item_count, {
        count: _vm.$n(_vm.getOffersMetrics.unviewed_item_count)
      })
      : null,"active-class":""},nativeOn:{"click":function($event){return _vm.selectSection.apply(null, arguments)}}}),_c('modify-search-view',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchActive),expression:"searchActive"}],attrs:{"show":_vm.searchActive,"edit":_vm.isSearchEdit},on:{"close":function($event){_vm.searchActive = false},"save-search":_vm.saveSearch,"toggle-countries-modal":_vm.toggleCountriesDivisionsModal}}),_c('div',{staticClass:"tw-mb-6"},[_c('ctk-collapse-category-item',{attrs:{"title":_vm.$t('proposal_list.title'),"icon":'bid-2'}}),_c('ctk-collapse-nav-item',{staticClass:"proposal-section",attrs:{"to":{
        name: 'ProposalStates',
        params: {
          state: 'pending'
        }
      },"title":_vm.$t('offer.proposals.filters.pending'),"count":_vm.getProposalsMetrics.pending,"exact":true},nativeOn:{"click":function($event){return _vm.selectSection.apply(null, arguments)}}}),_c('ctk-collapse-nav-item',{staticClass:"proposal-section",attrs:{"to":{
        name: 'Proposals'
      },"title":_vm.$t('offer.proposals.filters.all'),"count":_vm.getProposalsMetrics.total,"exact":true},nativeOn:{"click":function($event){return _vm.selectSection.apply(null, arguments)}}})],1),_c('searches-saved-section',{staticClass:"tw-mb-8",attrs:{"open":_vm.isSavedSearchesSectionOpen},on:{"modify-current-search":_vm.searchEdit,"open-new-search-section":function($event){_vm.$router.push({ name: 'Offers' }).catch(function () {})},"selected":_vm.selectSection,"toggle":function($event){return _vm.goToSearches($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }