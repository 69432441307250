<template>
  <div class="dimensions">
    <dimension
      v-for="(dimension, key) in dimensions"
      :key="key"
      v-model="dimension.value"
      :type="key"
      :has-minimum="dimension.hasMinimum"
      :dimension="dimension"
      @input="change(key)"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { debounce } from 'underscore'
  import { mapGetters } from 'vuex'

  import Dimension from './_subs/Dimension/index.vue'

  /**
   * @module component - Dimensions
   */
  export default defineComponent({
    name: 'Dimensions',
    components: {
      Dimension
    },
    data () {
      return {
        dimensions: {
          length: { min: 0, max: 1320, interval: 5, unit: 'cm', value: { min: null, max: null }, hasMinimum: true },
          width: { min: 10, max: 240, interval: 5, unit: 'cm', value: { min: null, max: null } },
          height: { min: 10, max: 270, interval: 5, unit: 'cm', value: { min: null, max: null } },
          weight: { min: 50, max: 28000, interval: 50, unit: 'kg', value: { min: null, max: null } }
        }
      }
    },
    computed: {
      ...mapGetters('offers', [
        'getCurrentSearch'
      ])
    },
    watch: {
      getCurrentSearch: {
        handler: function () {
          this.setInitialDimensions()
        },
        deep: true
      }
    },
    created () {
      this.setInitialDimensions()
    },
    methods: {
      setInitialDimensions () {
        /**
         * Set the default dimension values
         */
        Object.keys(this.dimensions).forEach((k) => {
          this.dimensions[k].value.max = this.getCurrentSearch[`max_${k}`]
        })
        this.dimensions.length.value.min = this.getCurrentSearch.min_length || 0
      },
      change (key) {
        this.trackEvent(key)

        const { length, width, height, weight } = this.dimensions
        const dimensions = {
          length: length.value,
          width: width.value,
          height: height.value,
          weight: weight.value
        }
        this.$emit('input', dimensions)
      },
      trackEvent: debounce(function (key) {
        if (this.$matomo) {
          const eventCorrespondance = {
            length: 'MaxLength',
            width: 'MaxWidth',
            weight: 'MaxWeight',
            height: 'MaxHeight'
          }

          if (key === 'length') this.$matomo.trackEvent('Freight Searches', 'Changed Load', 'MinLength', this.dimensions[key].value.min)
          this.$matomo.trackEvent('Freight Searches', 'Changed Load', eventCorrespondance[key], this.dimensions[key].value.max)
        }
      }, 600),
      setDimensions (dimensions) {
        const newDimension = Object.assign({}, this.dimensions)
        Object.keys(dimensions).forEach((key) => {
          if (key !== 'key') {
            newDimension[key].value = dimensions[key]
          }
        })

        this.dimensions = newDimension
      }
    }
  })
</script>

<style lang="scss" scoped>

  .dimensions {
    .sliders-container {
      position: relative;

      .dimension-value-container {
        position: absolute;
        right: 0;
        top: -3px;
      }
    }

    .divider {
      width: 100%;
      border-top: 2px solid #F6F6F6;
    }
  }

</style>
