<template>
  <offers-aside-layout
    id="offers"
    ref="offers"
    class="offers"
  >
    <template
      v-slot:header
    >
      <offers-suggestion-container
        v-if="getOffersSuggestions.length > 0 && isOffersSuggestionsVisible"
      />

      <offers-list-header />
    </template>

    <offers-list
      v-if="getOffersList.length"
      class="tw-min-h-0"
      ref="offersList"
      data-test="list"
    />

    <offers-empty-state
      v-else
      data-test="empty"
    />
  </offers-aside-layout>
</template>

<script>
  import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api'
  import { mapActions } from 'vuex'
  import UserIdleTracker from 'user-idle-tracker'

  import OffersAsideLayout from '@/views/Carriers/Offers/components/OffersAsideLayout/index.vue'
  import OffersList from '@/views/Carriers/Offers/components/OffersList/index.vue'
  import OffersEmptyState from '@/views/Carriers/Offers/components/OffersEmptyState/index.vue'
  import OffersSuggestionContainer from '@/views/Carriers/Offers/components/OffersList/_subs/OffersSuggestionContainer/index.vue'
  import OffersListHeader from '@/views/Carriers/Offers/components/OffersList/_subs/OffersListHeader/index.vue'

  import { EventBus } from '@/services/EventBus'
  import store from '@/store'
  import useStore from '@/composables/useStore'
  import useWait from '@/composables/useWait'
  import useRoute from '@/composables/useRoute'
  import useRouter from '@/composables/useRouter'

  /**
   * @function fetchOffers
   * @param {{[key: string]: any}} route
   */
  function fetchOffers (route) {
    return new Promise((resolve) => {
      /**
       * Fetch the offers associated with the search
       */
      // eslint-disable-next-line
      new Promise((r) => {
        // @ts-ignore
        if (!store.getters.isAppReady || store.state.offers.searches.length === 0) {
          store.dispatch('offers/getSearch')
            .then(({ data }) => {
              r(data.items)
            })
            .catch(() => {})
        } else {
          /**
           * The app is loaded, we redirect to the search if any.
           */
          if (route.name === 'Searches' && !route.params.uuid) {
            // @ts-ignore
            resolve({ name: 'Searches', params: { uuid: store.state.offers.searches[0].uuid } })
          } else {
            // @ts-ignore
            r(store.state.offers.searches)
          }
        }
      })
        .then((/** @type {Array<{ uuid: string }>} */ searches) => {
          /**
           * Once the search list has been updated, assign the matching search uuid in the store
           * Then fetch the offers
           */
          if (route.name === 'Searches') {
            const search = searches.find(x => x.uuid === route.params.uuid)
            if (search || searches.length) {
              store.dispatch('offers/setCurrentSearch', { search: search || searches[0] })
            }

            store.dispatch('offers/getOffers')
              .catch(() => {})
              .finally(() => {
                resolve(false)
              })
          } else {
            /**
             * The user is going to the offers view, without any searches.
             * Just by precaution, we reset the current search to fetch all the offers.
             */
            store.dispatch('offers/resetCurrentSearch')
            store.dispatch('offers/getOffers')
              .catch(() => {})
              .finally(() => {
                resolve(false)
              })
          }
        })
    })
  }

  export default defineComponent({
    name: 'Offers',
    metaInfo () {
      return {
        title: this.$t('offers.title')
      }
    },
    components: {
      OffersAsideLayout,
      OffersList,
      OffersEmptyState,
      OffersSuggestionContainer,
      OffersListHeader
    },
    // @ts-ignore
    beforeRouteEnter (to, from, next) {
      /**
       * Reset the offer whenever the user comes to the offer view.
       */
      store.dispatch('offers/resetCurrentOffer')
      store.dispatch('offers/resetCurrentProposal')

      next()
    },
    setup () {
      const store = useStore()
      const wait = useWait()
      const route = useRoute()
      const router = useRouter()

      /** @type {import('@vue/composition-api').Ref<any>} */
      const userTracker = ref(null)
      const isoDialogDirection = ref('pickup')
      const isoDialogVisible = ref(false)
      /** @type {import('@vue/composition-api').Ref<any>} */
      const listEl = ref(null)

      const getCurrentOffer = computed(() => store.value.getters['offers/getCurrentOffer'])
      const getOffersList = computed(() => store.value.getters['offers/getOffersList'])
      const getOffersSuggestions = computed(() => store.value.getters['offers/getOffersSuggestions'])
      const isOffersSuggestionsVisible = computed(() => store.value.getters['offers/isOffersSuggestionsVisible'])

      /**
       * Scroll to the top of the offers list.
       */
      function scrollTop () {
        if (!listEl.value) return

        const el = listEl.value.$refs.content
        if (el) {
          el.scrollTop = 0
        }
      }

      /**
       * @function getOffers
       * @param {object} params
       * @param {number?} params.pageNumber
       * @param {boolean} [params.fromFilter]
       */
      function getOffers (params) {
        wait.start('loading offers')
        store.value.dispatch('offers/getOffers', params)
          .catch(() => {})
          .finally(() => {
            /**
             * Whenever we fetched new offers, scroll to the top of the list.
             */
            scrollTop()

            wait.end('loading offers')
          })
      }

      /**
       * Setup the mouse user iddle tracker.
       * Whenever we detect the user is idle, we want to refresh the offers list.
       */
      function handleUserIdle () {
        userTracker.value = new UserIdleTracker(() => {
          if (!document.hidden && !getCurrentOffer.value) {
            getOffers({ pageNumber: null })
          }

          handleUserIdle()
        }, 60000)
      }

      /**
       * @function loadMoreOffers
       */
      function loadMoreOffers () {
        if (wait.is('loading more offers') || wait.is('loading offers')) return

        if (!(store.value.state.offers.offerPagination >= store.value.state.offers.currentOffersMeta.pagination.page_count || wait.is('loading more offers'))) {
          wait.start('loading more offers')
          store.value.commit('offers/SET_OFFER_PAGINATION', store.value.state.offers.offerPagination + 1)

          store.value.dispatch('offers/loadMoreOffers')
            .catch(() => {})
            .finally(() => {
              wait.end('loading more offers')
            })
        }
      }

      wait.start('loading offers')
      fetchOffers(route.value)
        .then((route) => {
          if (route) router.value.push(route).catch(() => {})

          store.value.dispatch('offers/refreshSearchesCount')
        })
        .finally(() => {
          store.value.dispatch('setAppReady', true)
          wait.end('loading offers')
        })

      onMounted(() => {
        handleUserIdle()

        EventBus.$on('get-offers', (/** @type {boolean} */ fromFilter) => {
          getOffers({ pageNumber: null, fromFilter })
        })
      })

      onUnmounted(() => {
        if (userTracker.value) {
          userTracker.value.destroy()
        }

        EventBus.$off('get-offers')
      })

      watch(route, (to) => {
        wait.start('loading offers')
        fetchOffers(to)
          .finally(() => {
            wait.end('loading offers')
            store.value.dispatch('offers/refreshSearchesCount')
          })
      })

      return {
        userTracker,
        isoDialogDirection,
        isoDialogVisible,

        getCurrentOffer,
        getOffersList,
        getOffersSuggestions,
        isOffersSuggestionsVisible,

        loadMoreOffers
      }
    },
    methods: {
      ...mapActions('offers', [
        'resetCurrentOffer',
        'setKeyboardStatus',
        'setOffersSuggestionsVisibility',
        'resetCurrentSearch',
        'setCurrentSearch'
      ])
    }
  })
</script>
