<template>
  <button
    v-b-tooltip.hover="title"
    :title="title"
    :class="{
      'active': active
    }"
    class="dimension tw-border-none tw-cursor-pointer tw-py-0 tw-px-4"
    @click="select"
  >
    <component :is="icon" />
  </button>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import PLTruck from './PLTruck/index.vue'
  import SPLTruck from './SPLTruck/index.vue'
  import STruck from './STruck/index.vue'
  import VLTruck from './VLTruck/index.vue'
  import VTruck from './VTruck/index.vue'

  /**
   * @module component - dimension
   * @param {boolean} active
   * @param {string} type
   * @emits select
   */
  export default defineComponent({
    name: 'Dimension',
    components: {
      PLTruck,
      SPLTruck,
      STruck,
      VLTruck,
      VTruck
    },
    props: {
      index: {
        type: Number,
        default: null
      },
      type: {
        type: String,
        required: true,
        validator: (/** @type {string} */ value) => ['S', 'V', 'VL', 'PL', 'SPL']
          .includes(value.toUpperCase())
      },
      dimensions: {
        type: Array,
        default: null
      }
    },
    computed: {
      ...mapGetters('offers', [
        'getSelectedDimension'
      ]),
      /**
       * @function title
       * @returns {import('vue-i18n').TranslateResult|string}
       */
      title () {
        return this.$t(`offer.predefined_dimensions.${this.type.toLowerCase()}`)
      },
      /**
       * @function icon
       * @returns {any}
       */
      icon () {
        const icons = {
          S: 'STruck',
          SPL: 'SPLTruck',
          PL: 'PLTruck',
          VL: 'VLTruck',
          V: 'VTruck'
        }

        // @ts-ignore
        return icons[this.type.toUpperCase()]
      },
      /**
       * @function active
       * @returns {boolean}
       */
      active () {
        const dimensionIndex = this.dimensions
          .findIndex((/** @type {{key: string}} */ value) => value.key === this.getSelectedDimension)

        return this.index <= dimensionIndex
      }
    },
    methods: {
      /**
       * Called whenever the user clicks on the button
       * @function select
       */
      select () {
        this.$emit('select', this.type)
      }
    }
  })
</script>

<style lang="scss" scoped>

  .dimension {
    background-color: transparent;
    height: 45px;
    transition: box-shadow 200ms;

    &-icon {
      transition: transform 200ms;
    }

    &.active {
      border-bottom: 1px solid $info;
    }

    &:focus {
      outline: none;
    }

    &:hover,
    &:focus {
      &:not(.active) {
        border-bottom: 1px solid $divider;
      }
    }
  }

</style>

<style lang="scss">

  /* stylelint-disable */
  .dimension {
    svg {
      transition: transform 200ms;
      width: 100%;
      max-width: 100%;

      path {
        fill: $divider;
      }
    }

    &:focus{
      box-shadow: 0 0 0 0.2rem rgba($info, 0.5);
    }

    &:hover, &:focus, &.active{
      svg{
        transform: scale(1.2);
      }
    }

    &.active:hover, &.active:focus{
      svg{
        transform: scale(1.4);
      }
    }

    &.active {
      svg path {
        fill: $info;
      }
    }
  }
  /* stylelint-enable */

</style>
